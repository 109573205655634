export default {
  setModal(state, payload) {
    state.modal = payload.value;
  },
  setReviewModal(state, payload) {
    state.reviewModal = payload.value;
  },
  setConfirmationModal(state, payload) {
    state.confirmationModal = payload.value;
  },
  setOutstanding(state, payload) {
    state.outstanding = payload.value;
  },
  setWeek(state, payload) {
    state.week = payload.value;
  },
  setCurrPayment(state, payload) {
    state.currPayment = payload.value;
  },
};
