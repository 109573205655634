<template>
  <div id="past-order">
    <div id="assign-heading">
      <h2>Previous Deliveries</h2>
    </div>
    <div id="table">
      <div id="heading">
        <h2 class="heading-text">Driver Name</h2>
        <h2 class="heading-text">Delivery Charge</h2>
        <h2 class="heading-text">Payment Mode</h2>
        <h2 class="heading-text">Order ID</h2>
        <h2 class="heading-text">Destination</h2>
        <h2 class="heading-text">Date</h2>
        <h2 class="heading-text"></h2>
      </div>
      <div id="underline"></div>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div v-else>
        <div id="items" v-if="Show">
          <div id="content" v-for="(order, index) in Orders" :key="index">
            <div class="item">
              <h2 class="item-text" v-if="order.driver.name">
                {{ order.driver.name }}
              </h2>
              <h2 class="item-text" v-else>Not Assigned</h2>
              <h2 class="item-text">£ {{ order.delivery_price }}</h2>
              <h2 class="item-text">{{ order.mode_of_payment }}</h2>
              <h2 class="item-text">{{ order.id }}</h2>
              <h2 class="item-text">{{ order.address }}</h2>
              <h2 class="item-text">{{ order.createdAt }}</h2>
              <div id="details" @click="redirect(order.id)">Review</div>
            </div>
            <div class="underline1"></div>
          </div>
        </div>
        <div v-else>
          <div v-if="Message == 1" class="message-comp">
            <empty-list msg="No completed order"></empty-list>
          </div>
          <div v-if="Message == 2" class="message-comp">
            <server-crash msg="Something went wrong at our end"></server-crash>
          </div>
          <div v-if="Message == 3" class="message-comp">
            <error-comp msg="Failed to fetch orders"></error-comp>
          </div>
        </div>
      </div>
    </div>
    <div id="pagination" v-if="Show">
      <div class="page" v-if="Total > 5" @click="prevRow">{{ prev }}</div>
      <div class="page" @click="prevFn">{{ prev1 }}</div>
      <div v-for="(page, index) in pages" :key="index">
        <div
          :class="Selected == page ? 'active-page' : 'page'"
          @click="changePage(page)"
          v-if="page <= Total"
        >
          {{ page }}
        </div>
      </div>

      <div class="page" @click="nextFn">{{ next1 }}</div>
      <div class="page" v-if="Total > 5" @click="nextRow">{{ next }}</div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import LoaderComp from "../general/loader-comp.vue";
import EmptyList from "../messages/empty-comp.vue";
import ServerCrash from "../messages/server-crash.vue";
import ErrorComp from "../messages/error-comp.vue";
export default {
  components: { LoaderComp, EmptyList, ServerCrash, ErrorComp },
  data() {
    return {
      orders: [],
      message: 0,
      prev: "<<",
      prev1: "<",
      next: ">>",
      next1: ">",
      pages: [],
      activePage: 1,
      totalPages: 0,
    };
  },
  computed: {
    Total() {
      return this.totalPages;
    },
    Selected() {
      return this.activePage;
    },
    Pages() {
      return this.pages;
    },
    Orders() {
      return this.orders;
    },
    Show() {
      try {
        let val = Object.keys(this.orders).length;
        if (val > 0) return true;
        else return false;
      } catch (err) {
        return false;
      }
    },
    Loading() {
      return this.$store.getters["user/getLoading"];
    },
    Message() {
      return this.message;
    },
  },
  methods: {
    redirect(value) {
      this.$router.push({ name: "deliveryReview", params: { id: value } });
    },
    async prevFn() {
      this.activePage =
        this.activePage - 1 > 0 ? this.activePage - 1 : this.activePage;
      if (this.activePage < this.pages[0]) {
        await this.prevRow();
      } else await this.fetchData(this.activePage);
    },
    async nextFn() {
      this.activePage =
        this.activePage + 1 <= this.totalPages
          ? this.activePage + 1
          : this.activePage;
      if (this.activePage > this.pages[4]) {
        await this.nextRow();
      } else await this.fetchData(this.activePage);
    },
    async prevRow() {
      if (this.pages[0] != 1) {
        this.pages = this.pages.map((Element) => Element - 5);
        await this.fetchData(this.pages[0]);
        this.activePage = this.pages[0];
      }
    },
    async nextRow() {
      if (this.page[4] <= this.totalPages - 5) {
        this.pages = this.pages.map((Element) => Element + 5);
        await this.fetchData(this.pages[0]);
        this.activePage = this.pages[0];
      }
    },
    async changePage(value) {
      this.activePage = value;
      await this.fetchData(value);
    },
    async fetchData(value) {
      this.$store.commit("user/setLoading", { value: true });
      let res = await this.$store.dispatch("orders/pastOrders", {
        value: value,
      });
      this.$store.commit("user/setLoading", { value: false });
      if (res.status == 200) {
        this.orders = res.data;
        this.totalPages = res.pages;
        if (res.data.length == 0) {
          this.message = 1;
        }
      } else if (res.status == 500) {
        this.message = 2;
      } else {
        this.message = 3;
      }
    },
  },
  async mounted() {
    if (!Cookies.get("authenticated")) {
      this.$router.push({ name: "landing" });
    }
    this.$store.commit("user/setLoading", { value: true });
    let res = await this.$store.dispatch("orders/pastOrders", { value: 1 });
    this.$store.commit("user/setLoading", { value: false });
    if (res.status == 200) {
      this.orders = res.data;
      this.totalPages = res.pages;
      for (let i = 1; i <= this.totalPages; i++) {
        if (i > 5) {
          break;
        }
        this.pages.push(i);
      }
      if (res.data.length == 0) {
        this.message = 1;
      }
    } else if (res.status == 500) {
      this.message = 2;
    } else {
      this.message = 3;
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
#past-order {
  width: 100vw;
  margin-top: 10px;
  height: auto;
  overflow-x: hidden;
  padding-bottom: 5.75vh;
}
#past-order::-webkit-scrollbar {
  display: none;
}
.message-comp {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  #assign-heading {
    padding-left: 3vw;
    padding-top: 2vh;
  }
  #assign-heading h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 15px;
  }
  #table {
    width: 96vw;
    height: 60vh;
    margin-top: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    overflow: auto;
  }
  #table::-webkit-scrollbar {
    display: none;
  }
  #heading {
    width: 92vw;
    display: grid;
    grid-template-columns: 28vw 18vw 20vw 30vw 40vw 38vw 28vw;
    grid-gap: 3vw;
    height: auto;
  }
  .heading-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #underline {
    width: 232vw;
    background: #8d8d8d;
    height: 1px;
    margin-top: 13px;
    margin-bottom: 21px;
  }
  .underline1 {
    width: 232vw;
    background: #8d8d8d;
    height: 1px;
    margin-top: 1px;
    margin-bottom: 19px;
  }
  #items {
    height: 55vh;
    overflow: auto;
    width: fit-content;
    padding-top: 1.5vh;
  }
  #items::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: grid;
    grid-template-columns: 28vw 18vw 20vw 30vw 40vw 38vw 28vw;
    grid-gap: 3vw;
    padding-bottom: 0.75vh;
    padding-top: 0.75vh;
  }
  .item-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #details {
    border-radius: 16px;
    background: #25ad34;
    width: 28vw;
    height: 32px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: -5px;
  }
  #loader {
    width: 94vw;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #pagination {
    width: 100vw;
    margin-top: 2vh;
    margin-bottom: 4vh;
    display: flex;
    justify-content: center;
  }
  .page {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.55vw;
    margin-right: 0.55vw;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.684);
    cursor: pointer;
  }
  .active-page {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.55vw;
    margin-right: 0.55vw;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #assign-heading {
    padding-left: 3vw;
    padding-top: 2vh;
  }
  #assign-heading h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 15px;
  }
  #table {
    width: 96vw;
    height: 64vh;
    margin-top: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    overflow: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  #table::-webkit-scrollbar {
    display: none;
  }
  #heading {
    width: 92vw;
    display: grid;
    grid-template-columns: 13vw 9vw 10vw 15vw 15vw 18vw 14vw;
    grid-gap: 1vw;
    height: 5vh;
  }
  .heading-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #underline {
    width: 104vw;
    background: #8d8d8d;
    height: 1px;
    margin-top: 13px;
    margin-bottom: 21px;
  }
  .underline1 {
    width: 104vw;
    background: #8d8d8d;
    height: 1px;
    margin-top: 1px;
    margin-bottom: 19px;
  }
  #items {
    height: 55vh;
    overflow: auto;
    width: fit-content;
    padding-top: 1.5vh;
  }
  #items::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: grid;
    grid-template-columns: 13vw 9vw 10vw 15vw 15vw 18vw 14vw;
    grid-gap: 1vw;
    padding-bottom: 0.75vh;
    padding-top: 0.75vh;
  }
  .item-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #details {
    border-radius: 16px;
    background: #25ad34;
    width: 12vw;
    height: 42px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left: 4vw;
    margin-top: -3px;
  }
  #loader {
    width: 94vw;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #pagination {
    width: 100vw;
    margin-top: 2vh;
    margin-bottom: 4vh;
    display: flex;
    justify-content: center;
  }
  .page {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.75vw;
    margin-right: 0.75vw;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.684);
    cursor: pointer;
  }
  .active-page {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.75vw;
    margin-right: 0.75vw;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 961px) {
  #assign-heading {
    padding-left: 3vw;
    padding-top: 2vh;
  }
  #assign-heading h2 {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 15px;
  }
  #table {
    width: 96vw;
    height: 63vh;
    margin-top: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    overflow: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  #table::-webkit-scrollbar {
    display: none;
  }
  #heading {
    width: 92vw;
    display: grid;
    grid-template-columns: 13vw 9vw 10vw 13vw 15vw 12vw 13vw;
    grid-gap: 1vw;
    height: 5vh;
  }
  .heading-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #underline {
    width: 92vw;
    background: #8d8d8d52;
    height: 1px;
    margin-top: 13px;
    margin-bottom: 21px;
  }
  .underline1 {
    width: 92vw;
    background: #8d8d8d52;
    height: 1px;
    margin-top: 1px;
    margin-bottom: 19px;
  }
  #items {
    height: 55vh;
    overflow: auto;
    width: fit-content;
    padding-top: 1.5vh;
  }
  #items::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: grid;
    grid-template-columns: 13vw 9vw 10vw 13vw 15vw 12vw 13vw;
    grid-gap: 1vw;
    padding-top: 0.75vh;
    padding-bottom: 0.75vh;
  }
  .item-text {
    color: #413f3f;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    word-wrap: break-word;
  }
  #details {
    border-radius: 16px;
    background: #25ad34;
    width: 8vw;
    height: 42px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left: 4vw;
    margin-top: -8px;
    cursor: pointer;
  }
  #loader {
    width: 94vw;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #pagination {
    width: 100vw;
    margin-top: 2vh;
    margin-bottom: 4vh;
    display: flex;
    justify-content: center;
  }
  .page {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.684);
    cursor: pointer;
  }
  .active-page {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #25ad34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
}
</style>
