<template>
    <div id="loader">
        <div class="loader">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>

@media only screen and (max-width:480px){
    .dot {
    width: 10px;
    height: 10px;
    background-color: #25AD34;
    border-radius: 50%;
    margin: 0 5px;
    animation: wave 1.5s infinite ease-in-out;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dot1 {
    animation-delay: 0.2s;
}

.dot2 {
    animation-delay: 0.4s;
}

.dot3 {
    animation-delay: 0.6s;
}

@keyframes wave {
    0%, 60%, 100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-15px);
    }
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    .dot {
    width: 15px;
    height: 15px;
    background-color: #25AD34;
    border-radius: 50%;
    margin: 0 5px;
    animation: wave 1.5s infinite ease-in-out;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dot1 {
    animation-delay: 0.2s;
}

.dot2 {
    animation-delay: 0.4s;
}

.dot3 {
    animation-delay: 0.6s;
}

@keyframes wave {
    0%, 60%, 100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-15px);
    }
}
}
@media only screen and (min-width:961px){
    .dot {
    width: 20px;
    height: 20px;
    background-color: #25AD34;
    border-radius: 50%;
    margin: 0 5px;
    animation: wave 1.5s infinite ease-in-out;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dot1 {
    animation-delay: 0.2s;
}

.dot2 {
    animation-delay: 0.4s;
}

.dot3 {
    animation-delay: 0.6s;
}

@keyframes wave {
    0%, 60%, 100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-15px);
    }
}
}
</style>