<template>
  <div id="modal2" v-if="value.address">
    <div id="modal-header">
      <h2>Order Details</h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        @click="close"
      >
        <path
          d="M26.6667 0H3.33333C2.44928 0 1.60143 0.351189 0.976311 0.976311C0.351189 1.60143 0 2.44928 0 3.33333V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V3.33333C30 2.44928 29.6488 1.60143 29.0237 0.976311C28.3986 0.351189 27.5507 0 26.6667 0ZM21 23.3333L15 17.3333L9 23.3333L6.66667 21L12.6667 15L6.66667 9L9 6.66667L15 12.6667L21 6.66667L23.3333 9L17.3333 15L23.3333 21L21 23.3333Z"
          fill="#D9D9D9"
        />
      </svg>
    </div>
    <div id="section1">
      <div id="orderNumber">
        <h2>Order No.</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="146"
          height="4"
          viewBox="0 0 146 4"
          fill="none"
        >
          <path
            d="M0.147135 2.07422C0.147135 2.8106 0.744089 3.40755 1.48047 3.40755C2.21685 3.40755 2.8138 2.8106 2.8138 2.07422C2.8138 1.33784 2.21685 0.740885 1.48047 0.740885C0.744089 0.740885 0.147135 1.33784 0.147135 2.07422ZM142.711 2.07421C142.711 2.81059 143.308 3.40754 144.044 3.40754C144.781 3.40754 145.378 2.81059 145.378 2.07421C145.378 1.33783 144.781 0.740873 144.044 0.740873C143.308 0.740873 142.711 1.33783 142.711 2.07421ZM1.48047 2.32422L144.044 2.32421L144.044 1.82421L1.48047 1.82422L1.48047 2.32422Z"
            fill="black"
          />
        </svg>
        <h3>{{ value.id }}</h3>
      </div>
      <!-- <div id="orderItems" >
                <h2>Order Items</h2>
                <h3>{{ Details.deliveryItems }}</h3>
            </div> -->
      <div id="orderAddress">
        <h2>Delivery Address</h2>
        <h3>{{ value.address }}</h3>
      </div>
    </div>
    <div id="section2" v-if="value.customer_name">
      <h2>Customer Details</h2>
      <div id="sec-21">
        <div id="sec-21-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
          >
            <path
              d="M39.8996 22.8003C39.8996 25.8237 38.6985 28.7233 36.5606 30.8612C34.4227 32.9991 31.5231 34.2001 28.4996 34.2001C25.4761 34.2001 22.5765 32.9991 20.4386 30.8612C18.3007 28.7233 17.0996 25.8237 17.0996 22.8003C17.0996 19.7768 18.3007 16.8772 20.4386 14.7393C22.5765 12.6014 25.4761 11.4004 28.4996 11.4004C31.5231 11.4004 34.4227 12.6014 36.5606 14.7393C38.6985 16.8772 39.8996 19.7768 39.8996 22.8003Z"
              fill="#25AD34"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.3372 56.9766C12.1367 56.3667 0 43.8496 0 28.4997C0 12.7593 12.7594 0 28.5 0C44.2405 0 57 12.7593 57 28.4997C57 44.2401 44.2405 56.9994 28.5 56.9994C28.3699 57.0002 28.2397 57.0002 28.1095 56.9994C27.8516 56.9994 27.5937 56.9908 27.3372 56.9766ZM10.2115 46.483C9.99846 45.8711 9.92593 45.219 9.99934 44.5752C10.0728 43.9314 10.2902 43.3124 10.6356 42.7642C10.981 42.2159 11.4454 41.7525 11.9944 41.4083C12.5434 41.0641 13.1629 40.848 13.8068 40.7759C24.9161 39.5462 32.1523 39.6573 43.2074 40.8016C43.8522 40.8687 44.4732 41.0821 45.0231 41.4256C45.573 41.769 46.0373 42.2333 46.3805 42.7833C46.7238 43.3333 46.9371 43.9543 47.0041 44.5991C47.071 45.244 46.99 45.8956 46.7671 46.5044C51.5051 41.7106 54.1585 35.2398 54.15 28.4997C54.15 14.3339 42.6659 2.84997 28.5 2.84997C14.3341 2.84997 2.85 14.3339 2.85 28.4997C2.85 35.5049 5.65868 41.8546 10.2115 46.483Z"
              fill="#25AD34"
            />
          </svg>
        </div>
        <div id="sec-21-right">
          <h3>{{ value.customer_name }}</h3>
          <h3>{{ value.customer_phone }}</h3>
        </div>
      </div>
    </div>
    <div id="section2" v-if="value.driver.name">
      <h2>Driver Details</h2>
      <div id="sec-21" v-if="value.driver.name">
        <div id="sec-21-left">
          <img :src="value.driver.image" v-if="value.driver.image" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            v-else
          >
            <path
              d="M39.8996 22.8003C39.8996 25.8237 38.6985 28.7233 36.5606 30.8612C34.4227 32.9991 31.5231 34.2001 28.4996 34.2001C25.4761 34.2001 22.5765 32.9991 20.4386 30.8612C18.3007 28.7233 17.0996 25.8237 17.0996 22.8003C17.0996 19.7768 18.3007 16.8772 20.4386 14.7393C22.5765 12.6014 25.4761 11.4004 28.4996 11.4004C31.5231 11.4004 34.4227 12.6014 36.5606 14.7393C38.6985 16.8772 39.8996 19.7768 39.8996 22.8003Z"
              fill="#25AD34"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.3372 56.9766C12.1367 56.3667 0 43.8496 0 28.4997C0 12.7593 12.7594 0 28.5 0C44.2405 0 57 12.7593 57 28.4997C57 44.2401 44.2405 56.9994 28.5 56.9994C28.3699 57.0002 28.2397 57.0002 28.1095 56.9994C27.8516 56.9994 27.5937 56.9908 27.3372 56.9766ZM10.2115 46.483C9.99846 45.8711 9.92593 45.219 9.99934 44.5752C10.0728 43.9314 10.2902 43.3124 10.6356 42.7642C10.981 42.2159 11.4454 41.7525 11.9944 41.4083C12.5434 41.0641 13.1629 40.848 13.8068 40.7759C24.9161 39.5462 32.1523 39.6573 43.2074 40.8016C43.8522 40.8687 44.4732 41.0821 45.0231 41.4256C45.573 41.769 46.0373 42.2333 46.3805 42.7833C46.7238 43.3333 46.9371 43.9543 47.0041 44.5991C47.071 45.244 46.99 45.8956 46.7671 46.5044C51.5051 41.7106 54.1585 35.2398 54.15 28.4997C54.15 14.3339 42.6659 2.84997 28.5 2.84997C14.3341 2.84997 2.85 14.3339 2.85 28.4997C2.85 35.5049 5.65868 41.8546 10.2115 46.483Z"
              fill="#25AD34"
            />
          </svg>
        </div>
        <div id="sec-21-right">
          <h3>{{ value.driver.name }}</h3>
          <h3>{{ value.driver.phone }}</h3>
        </div>
      </div>
    </div>
    <div id="section2" v-else>
      <h3>No driver assigned</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    close() {
      this.$store.commit("orders/setPastModal", { value: false });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #modal2 {
    display: none;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #modal2 {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  #modal2 {
    margin-top: -25vh;
    position: fixed;
    z-index: 1;
    width: 40vw;
    height: auto;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    background: #fff;
    margin-left: 30vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  #modal-header {
    margin-top: 3vh;
    width: 40vw;
    margin-bottom: 8vh;
  }
  #modal-header h2,
  #section2 h2 {
    color: var(--Brand-color-2, #413f3f);

    /* Sub text */
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    float: left;
    margin-left: 2vw;
    word-wrap: break-word;
  }
  #modal-header svg {
    float: left;
    margin-left: 37vw;
    margin-top: -4vh;
    cursor: pointer;
  }
  #section1 {
    width: 38vw;
    margin-left: 1vw;
    margin-right: 1vw;
    height: auto;
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  #orderNumber {
    display: inline-flex;
    width: 36vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    justify-content: center;
  }
  #orderNumber svg {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 6px;
  }
  #orderNumber h2 {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
  }
  #orderNumber h3 {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
  }

  #orderItems,
  #orderAddress {
    width: 36vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  #orderItems h2,
  #orderAddress h2 {
    color: var(--Brand-color-2, #413f3f);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
  }
  #orderItems h3,
  #orderAddress h3 {
    color: var(--Brand-color-2, #413f3f);

    /* Sub text */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    word-wrap: break-word;
  }
  #section2 {
    width: 38vw;
    margin-left: 1vw;
    margin-right: 1vw;
    height: auto;
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  #sec-21 {
    width: 34vw;
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    gap: 1.5vw;
    margin-top: 6vh;
  }
  #sec-21-left img {
    width: 67px;
    height: 67px;
    border-radius: 67px;
  }
  #sec-21-right {
    margin-top: -0.75vh;
  }
  #sec-21-right h3 {
    color: var(--Brand-color-2, #413f3f);
    /* Sub text */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }

  #section2 h3 {
    color: var(--Brand-color-2, #413f3f);
    /* Sub text */
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    text-align: center;
  }
}
</style>
