<template>
    <div id="menu-button" :class="active?'active':'passive'">
        <h2>{{ Text }}</h2>
    </div>
</template>

<script>
export default {
 props:['Text','Index'],
 computed:{
    active(){
        let value = this.$store.getters['user/getHomePage']
        if(value.toString() == this.Index) return true
        else return false
    }
 }   
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.active{
    border-radius: 6px;
    background: #25AD34;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    flex-shrink: 0;
}
.passive{
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    flex-shrink: 0;
}
.active h2{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}
.passive h2{
    color: #413F3F;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
@media only screen and (max-width:480px){
#menu-button{
    width:114px;
    height:64px;
}
.active h2{
    font-size: 18px;
    line-height: 20px;
    margin-top:10px
}
.passive h2{
    font-size: 18px;
    line-height: 20px;
    margin-top:10px
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #menu-button{
        width:140px;
        height:54px;
    }
    .active h2{
        text-align: center;
        line-height: 54px;
    }
    .passive h2{
        text-align: center;
        line-height: 54px;
    }
}
@media only screen and (min-width:961px){
    #menu-button{
        width:140px;
        height:54px;
        cursor: pointer;
    }
    .active h2{
        text-align: center;
        line-height: 54px;
    }
    .passive h2{
        text-align: center;
        line-height: 54px;
    }
    
}
</style>