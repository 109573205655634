export default {
  getForgotPage(state) {
    return state.forgotPage;
  },
  getHomePage(state) {
    return state.homePage;
  },
  getPaymentMode(state) {
    return state.paymentMode;
  },
  getAddress(state) {
    return state.address;
  },
  getCoordinates(state) {
    return { latitude: state.latitude, longitude: state.longitude };
  },
  getNotification(state) {
    return state.notification;
  },
  getNotificationData(state) {
    return state.notificationData;
  },
  getZipCode(state) {
    return state.zipCode;
  },
  getLoading(state) {
    return state.loading;
  },
};
