import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      paymentMethod: "",
      paymentFieldError: false,
      trackOrder: 0,
      trackIndicator: 0,
      customerCoordinates: {},
      driverCoordinates: {},
      outletCoordinates: {},
      driver: {},
      currOrder: {},
      newDetails: {},
      modal: false,
      address: "",
      edit_address: "",
      deliveryCost: 0.0,
      costCalculated: false,
      pastModal: false,
      activeModal: false,
      activeMileStone: 0,
    };
  },
  mutations,
  getters,
  actions,
};
