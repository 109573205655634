import LoginPage from "../views/onboarding/Login-Page.vue";
import SignupPage from "../views/onboarding/signup-page.vue";
import ForgotPassword from "../views/onboarding/forgot-password.vue";
import LandingPage from "../views/onboarding/landing-page.vue";
import StripeOnboard from "../views/onboarding/stripe-onboard.vue";
import ThankyouOutlet from "../views/onboarding/thankyou-outlet.vue";
import ThankyouDriver from "../views/onboarding/thankyou-driver.vue";
import ChangePassword from "../views/onboarding/change-password.vue";
import DriverError from "../views/onboarding/driver-error.vue";
const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "signup",
    path: "/signup",
    component: SignupPage,
  },
  {
    name: "forgot",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "landing",
    path: "/landing",
    component: LandingPage,
  },
  {
    name: "stripeOnboard",
    path: "/stripe-onboard",
    component: StripeOnboard,
  },
  {
    name: "thankyouOutlet",
    path: "/thankyou-outlet",
    component: ThankyouOutlet,
  },
  {
    name: "thankyouDriver",
    path: "/thankyou-driver",
    component: ThankyouDriver,
  },
  {
    name: "changePassword",
    path: "/change-password",
    component: ChangePassword,
  },
  {
    name: "driverError",
    path: "/driver/refresh-error",
    component: DriverError,
  },
];

export default routes;
