<template>
    <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
    </div>
    <div id="outstanding-payments" v-else>
        <div id="row-1">
         <div id="col1-outstanding">
             Driver Outstanding Payments
         </div>
         <div id="col2-outstanding">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" id="alert1">
                 <path d="M10.9375 8H9.3125V5H10.9375V8ZM10.9375 9.5H9.3125V11H10.9375V9.5ZM18.25 3.5V12.5C18.25 12.8978 18.0788 13.2794 17.774 13.5607C17.4693 13.842 17.056 14 16.625 14H5.25L2 17V3.5C2 3.10218 2.1712 2.72064 2.47595 2.43934C2.7807 2.15804 3.19402 2 3.625 2H16.625C17.056 2 17.4693 2.15804 17.774 2.43934C18.0788 2.72064 18.25 3.10218 18.25 3.5ZM16.625 3.5H3.625V13.4L4.6 12.5H16.625V3.5Z" fill="#D50505"/>
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none" id="alert2">
                 <path d="M14.7734 10.667H12.1328V5.54199H14.7734V10.667ZM14.7734 13.2295H12.1328V15.792H14.7734V13.2295ZM26.6562 2.97949V18.3545C26.6562 19.0341 26.378 19.6859 25.8828 20.1665C25.3876 20.647 24.716 20.917 24.0156 20.917H5.53125L0.25 26.042V2.97949C0.25 2.29987 0.528208 1.64809 1.02342 1.16753C1.51863 0.686969 2.19029 0.416992 2.89062 0.416992H24.0156C24.716 0.416992 25.3876 0.686969 25.8828 1.16753C26.378 1.64809 26.6562 2.29987 26.6562 2.97949ZM24.0156 2.97949H2.89062V19.892L4.475 18.3545H24.0156V2.97949Z" fill="#D50505"/>
               </svg>
               <h2 id="num">{{ payments }}</h2>
               <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none" id="dropup" v-if="Expand" @click="toggle">
                 <path d="M0.607628 7.9924L8.10763 0.492395C8.17728 0.422663 8.26 0.367343 8.35105 0.3296C8.4421 0.291857 8.53969 0.272431 8.63826 0.272431C8.73682 0.272431 8.83441 0.291857 8.92546 0.3296C9.01651 0.367343 9.09923 0.422663 9.16888 0.492395L16.6689 7.9924C16.8096 8.13313 16.8887 8.324 16.8887 8.52302C16.8887 8.72204 16.8096 8.91292 16.6689 9.05365C16.5281 9.19438 16.3373 9.27344 16.1383 9.27344C15.9392 9.27344 15.7484 9.19438 15.6076 9.05365L8.63826 2.08333L1.66888 9.05365C1.5992 9.12333 1.51647 9.1786 1.42543 9.21632C1.33438 9.25403 1.2368 9.27344 1.13825 9.27344C1.03971 9.27344 0.942126 9.25403 0.851082 9.21632C0.760036 9.1786 0.677311 9.12333 0.607628 9.05365C0.537947 8.98396 0.482672 8.90124 0.44496 8.81019C0.407248 8.71915 0.387836 8.62157 0.387836 8.52302C0.387836 8.42447 0.407248 8.32689 0.44496 8.23585C0.482672 8.1448 0.537947 8.06208 0.607628 7.9924Z" fill="black"/>
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" id="dropdown" v-else @click="toggle">
                 <path d="M20.5955 9.68925L13.0955 17.1892C13.0258 17.259 12.9431 17.3143 12.8521 17.352C12.761 17.3898 12.6634 17.4092 12.5649 17.4092C12.4663 17.4092 12.3687 17.3898 12.2777 17.352C12.1866 17.3143 12.1039 17.259 12.0342 17.1892L4.53425 9.68925C4.39351 9.54851 4.31445 9.35764 4.31445 9.15862C4.31445 8.9596 4.39351 8.76873 4.53425 8.62799C4.67498 8.48726 4.86585 8.4082 5.06487 8.4082C5.26389 8.4082 5.45476 8.48726 5.5955 8.62799L12.5649 15.5983L19.5342 8.62799C19.6039 8.55831 19.6867 8.50304 19.7777 8.46532C19.8687 8.42761 19.9663 8.4082 20.0649 8.4082C20.1634 8.4082 20.261 8.42761 20.352 8.46532C20.4431 8.50304 20.5258 8.55831 20.5955 8.62799C20.6652 8.69768 20.7205 8.7804 20.7582 8.87145C20.7959 8.96249 20.8153 9.06007 20.8153 9.15862C20.8153 9.25717 20.7959 9.35475 20.7582 9.44579C20.7205 9.53684 20.6652 9.61956 20.5955 9.68925Z" fill="black"/>
               </svg>
               
         </div>
        </div>
     <!--this is the row2 for expanded view-->
     <div id="row-2" v-if="Expand" :class="Expand?'expand':''">
        <div v-if="Message==0">
            <div id="outstanding-heading">
                <div class="heading-1" id="name-1">Driver Name</div>
                <div class="heading-1" id="reason-1">Pending</div>
                <div class="heading-1" id="action-1">Action</div>
            </div>
            <div class="separator-1"></div>
            <div id="rows" v-for="value,index in paymentDetails" :key="index">
                 <div class="outstanding-details"> 
                   <div class="outstanding-name">{{ value.driverName }}</div>
                   <div class="outstanding-reason">£ {{ value.paymentToDeposit }}</div>
                   <div class="outstanding-action" @click="settle(index)">
                     Call or Settle
                   </div>
                 </div>
                 <div class="separator-1">
                 </div>
            </div>  
        </div>
        <div class="message-comp" v-if="Message==1">
            <empty-list msg="No outstanding payments"></empty-list>
        </div>
        <div class="message-comp" v-if="Message==2">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp" v-if="Message==3">
            <error-message msg="Failed to fetch outstanding payments"></error-message>
        </div>
     </div>
     </div>
</template>

<script>
import LoaderComp from '../general/loader-comp.vue'
import EmptyList from '../messages/empty-comp.vue'
import ServerCrash from '../messages/server-crash.vue'
import ErrorMessage from '../messages/error-comp.vue'
export default {
    components:{LoaderComp,EmptyList,ServerCrash,ErrorMessage},
    data(){
        return{
            expand:false,
            message:0,
            loader:false
        }
    },
    computed:{
        payments(){ // this will return the length 
            let val = this.$store.getters['payments/getOutstanding']
            return val.length
        },
        paymentDetails(){
            return this.$store.getters['payments/getOutstanding']
        },
        Expand(){
            return this.expand;
        },
        Loading(){
            return this.loader
        },
        Empty(){
            let val = this.$store.getters['payments/getOutstanding']
            return val.length == 0
        },
        Message(){
            return this.message;
        }
    },
    methods:{
        toggle(){
            this.expand = !this.expand;
        },
        settle(val){
            this.$store.commit('payments/setId',{value:'order id'})
            let payments = this.$store.getters['payments/getOutstanding']
            payments = payments[val]
            payments['index'] = val
            this.$store.commit('payments/setCurrPayment',{value:payments})
            this.$store.commit('payments/setModal',{value:true})
        }
    },
    async mounted(){
        this.loader = true
        let res = await this.$store.dispatch('payments/outstandingPayments')
        this.loader=false
        if(res.status==200){
             this.$store.commit('payments/setOutstanding',{value:res.data})
             if(res.data.length==0){
                this.message=1
             }
        }
        else if(res.status==500){
            this.message=2
        }
        else {
            this.message=3
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.message-comp{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:480px){
    #outstanding-payments{
        border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
height:fit-content;
min-height: 48px;
max-height: 298px;
width:95vw;
margin-left:2.5vw;
margin-right:2.5vw;
overflow-x:hidden;
overflow-y:auto ;
}
#outstanding-payments::-webkit-scrollbar{
display: none;
}
#loader{
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    height:fit-content;
    min-height: 48px;
    max-height: 298px;
    width:95vw;
    margin-left:2.5vw;
    margin-right:2.5vw;
    overflow-x:hidden;
    overflow-y:auto ; 
    display: flex;
    justify-content: center;
}
#row-1{
    display:grid;
grid-template-columns: repeat(2,35vw);
grid-gap: 25vw;
}
.expand{
    animation:expand1;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}
#col1-outstanding{
    height:48px;
    overflow:hidden;
    width:35vw;
    color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
padding-left:1.5vw;
padding-top:7px;
}
#col2-outstanding{
    display: inline-flex;
    padding-top:15px;
    padding-left:10vw;
}
#alert1{
margin-top:3px;
}
#alert2{
    display: none;
}
#num{
    color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:5px;
margin-top:3px;
}
#dropdown{
    margin-left:4.5vw;
}
#dropup{
    margin-left:4.5vw;
    margin-top:6px;
}
#row-2{
width:95vw;
padding-top:15px;
padding-bottom:10px;
padding-left:2.5vw;
padding-right:2.5vw;
overflow-x: hidden;
overflow-y: auto;
height: fit-content;
max-height: 250px;
}
#row-2::-webkit-scrollbar{
    display: none;
}
#outstanding-heading{
    width:90vw;
    display: grid;
    grid-template-columns: repeat(3,25vw);
    gap:5vw;
}
.heading-1{
    color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
}
.separator-1{
height:.5px;
width:90vw;
background: #000000b4;
margin-top:7.5px;
margin-bottom:7.5px;
}
#rows{
    width:90vw;
    margin-top:10px;
    overflow-x:hidden ;
    overflow-y:auto ;
    height: fit-content;
}
#rows::-webkit-scrollbar{
    display: none;
}
.outstanding-details{
    width:90vw;
    display: grid;
    grid-template-columns:repeat(3,25vw);
    gap:5vw;
}

.outstanding-name{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 34px;
padding-left:2vw;
}
.outstanding-reason{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height:34px;
padding-left:2vw;
}
.outstanding-action{
    display: inline-flex;
padding: 6px 15px;
align-items: flex-start;
gap: 10px;
border-radius: 8px;
background: #25AD34;
width:83px;
height:34px;
margin-left:3.5vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

}
@media only screen and (min-width:481px) and (max-width:960px){
    #outstanding-payments{
        border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
height:fit-content;
min-height: 68px;
max-height: 498px;
width:90vw;
margin-left:5vw;
margin-right:5vw;
overflow-x:hidden;
overflow-y:auto ;
}
#outstanding-payments::-webkit-scrollbar{
display: none;
}
#loader{
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    height:fit-content;
    min-height: 68px;
    max-height: 498px;
    width:90vw;
    margin-left:5vw;
    margin-right:5vw;
    overflow-x:hidden;
    overflow-y:auto ;
    display: flex;
    justify-content: center;
}
#row-1{
    display:grid;
grid-template-columns: repeat(2,35vw);
grid-gap: 20vw;
}
.expand{
    animation:expand2;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}
#col1-outstanding{
    height:68px;
    overflow:hidden;
    width:35vw;
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
padding-left:3.5vw;
padding-top:10px;
}
#col2-outstanding{
    display: inline-flex;
    padding-top:20px;
    padding-left:15vw;
}
#alert1{
    display: none;
}
#alert2{
margin-top:3px;
}
#num{
    color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:10px;
margin-top:3px;
}
#dropdown{
    margin-left:4.5vw;
}
#dropup{
    margin-left:4.5vw;
    margin-top:6px;
}
#row-2{
width:90vw;
padding-top:17px;
padding-bottom:17px;
padding-left:2vw;
padding-right:2vw;
overflow-x: hidden;
overflow-y: auto;
height: fit-content;
max-height: 430px;
}
#row-2::-webkit-scrollbar{
    display: none;
}
#outstanding-heading{
    width:86vw;
    display: grid;
    grid-template-columns: repeat(3,27vw);
    gap:1.7vw;
}
.heading-1{
    color: #413F3F;
font-family: Inter;
font-size:20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: start;

}
#name-1{
    margin-left:2vw;
}
#reason-1{
    margin-left:4vw;
}
#action-1{
    margin-left:9vw;
}
.separator-1{
height:.5px;
width:86vw;
background: #000000b4;
margin-top:7.5px;
margin-bottom:7.5px;
}
#rows{
    width:86vw;
    margin-top:10px;
    overflow-x:hidden ;
    overflow-y:auto ;
    height: fit-content;
}
#rows::-webkit-scrollbar{
    display: none;
}
.outstanding-details{
    width:86vw;
    display: grid;
    grid-template-columns:repeat(3,27vw);
    gap:1.7vw;
}

.outstanding-name{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: 48px;
padding-left:2vw;
}
.outstanding-reason{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height:48px;
padding-left:2vw;
}
.outstanding-action{
border-radius: 8px;
background: #25AD34;
width:18vw;
height:48px;
margin-left:6.5vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 48px;
}
 
}
@media only screen and (min-width:961px){
    #outstanding-payments{
        border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
height:fit-content;
min-height: 68px;
max-height: 498px;
width:70vw;
margin-left:15vw;
margin-right:15vw;
overflow-x:hidden;
overflow-y:auto ;
}
#outstanding-payments::-webkit-scrollbar{
display: none;
}
#loader{
    border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
height:fit-content;
min-height: 68px;
max-height: 498px;
width:70vw;
margin-left:15vw;
margin-right:15vw;
overflow-x:hidden;
overflow-y:auto ;
display: flex;
justify-content: center; 
}
#row-1{
    display:grid;
grid-template-columns: repeat(2,35vw);
grid-gap: 5vw;
}
.expand{
    animation:expand2;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}
#col1-outstanding{
    height:68px;
    overflow:hidden;
    width:35vw;
    color: #413F3F;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 68px;
letter-spacing: 0.2px;
padding-left:1.5vw;
}
#col2-outstanding{
    display: inline-flex;
    padding-top:20px;
    padding-left:17vw;
}
#alert1{
    display: none;
}
#alert2{
margin-top:3px;
}
#num{
    color: #413F3F;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:15px;
margin-top:3.4px;
}
#dropdown{
    margin-left:3.5vw;
    margin-top:2px;
    cursor: pointer;
}
#dropup{
    margin-left:3.5vw;
    margin-top:9px;
    cursor: pointer;
}
#row-2{
width:70vw;
padding-top:10px;
padding-bottom:10px;
padding-left:3.5vw;
padding-right:3.5vw;
overflow-x: hidden;
overflow-y: auto;
height: fit-content;
max-height: 430px;
}
#row-2::-webkit-scrollbar{
    display: none;
}
#outstanding-heading{
    width:63vw;
    display: grid;
    grid-template-columns: repeat(3,21vw);
}
.heading-1{
    color: #413F3F;
font-family: Inter;
font-size:21px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: start;

}
#name-1{
    margin-left:2vw;
}
#reason-1{
    margin-left:4vw;
}
#action-1{
    margin-left:9vw;
}
.separator-1{
height:.5px;
width:63vw;
background: #000000b4;
margin-top:5.5px;
margin-bottom:5.5px;
}
#rows{
    width:63vw;
    margin-top:10px;
    overflow-x:hidden ;
    overflow-y:auto ;
    height: fit-content;
}
#rows::-webkit-scrollbar{
    display: none;
}
.outstanding-details{
    width:63vw;
    display: grid;
    grid-template-columns:repeat(3,21vw);
}

.outstanding-name{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 48px;
padding-left:2vw;
}
.outstanding-reason{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height:48px;
padding-left:3vw;
}
.outstanding-action{
border-radius: 8px;
background: #25AD34;
width:10vw;
height:48px;
margin-left:6.5vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 48px;
cursor: pointer;
}
 
}
@keyframes expand1{
    from{
        height: 48px;
    }
    to{
        height: fit-content;
    }
}
@keyframes expand2{
    from{
        height: 68px;
    }
    to{
        height: fit-content;
    }
}
</style>