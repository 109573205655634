import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      modal: false,
      reviewModal: false,
      orderId: "",
      confirmationModal: false,
      outstanding: [],
      week: [],
      currPayment: {},
    };
  },
  mutations,
  getters,
  actions,
};
