<template>
    <div id="modal">
        <div id="modal-header">
            <h2>Change in address</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" @click="close">
                <path d="M26.6667 0H3.33333C2.44928 0 1.60143 0.351189 0.976311 0.976311C0.351189 1.60143 0 2.44928 0 3.33333V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V3.33333C30 2.44928 29.6488 1.60143 29.0237 0.976311C28.3986 0.351189 27.5507 0 26.6667 0ZM21 23.3333L15 17.3333L9 23.3333L6.66667 21L12.6667 15L6.66667 9L9 6.66667L15 12.6667L21 6.66667L23.3333 9L17.3333 15L23.3333 21L21 23.3333Z" fill="#D9D9D9"/>
              </svg>
        </div>
        <div id="section1" v-if="Message==0">
            <div id="orderNumber">
                <h2>Order No.</h2>
                <svg xmlns="http://www.w3.org/2000/svg" width="146" height="4" viewBox="0 0 146 4" fill="none" @click="close">
                    <path d="M0.147135 2.07422C0.147135 2.8106 0.744089 3.40755 1.48047 3.40755C2.21685 3.40755 2.8138 2.8106 2.8138 2.07422C2.8138 1.33784 2.21685 0.740885 1.48047 0.740885C0.744089 0.740885 0.147135 1.33784 0.147135 2.07422ZM142.711 2.07421C142.711 2.81059 143.308 3.40754 144.044 3.40754C144.781 3.40754 145.378 2.81059 145.378 2.07421C145.378 1.33783 144.781 0.740873 144.044 0.740873C143.308 0.740873 142.711 1.33783 142.711 2.07421ZM1.48047 2.32422L144.044 2.32421L144.044 1.82421L1.48047 1.82422L1.48047 2.32422Z" fill="black"/>
                  </svg>
                <h3>{{ OrderNo }}</h3>
            </div>  
            <div class="orderAddress">
                <h2>Old Address</h2>
                <h3>{{ Details.data.orderOldAddress.address }},{{Details.data.orderOldAddress.zipcode}}</h3>
            </div>
            <div class="orderAddress">
                <h2>New Address</h2>
            <h3>{{ Details.data.orderNewAddress.address }},{{ Details.data.orderNewAddress.zipcode }}</h3>
            </div>
            
        </div>
        <div id="section2" v-if="Message==0">
            <div id="sec-21">
                <img :src="Details.driverData.driverImage" :alt="Details.driverData.driverName" v-if="Details.driverData.driverImage">
                <img src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :alt="Details.driverData.driverName" v-else>
                <h3 >{{ Details.driverData.driverName }}</h3>
            </div>
            <div id="sec-22">
                <h3 v-if="Details.driverData.driverPhone">{{ Details.driverData.driverPhone }}</h3>
                <div id="call-driver" @click="callDriver(Details.driverData.driverPhone)">Call Driver</div>
            </div>
        </div>
        <div id="section3" v-if="Message==0">
          <div class="sec-31">
            <h2>Order Value</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="137" height="3" viewBox="0 0 137 3" fill="none">
                <path d="M0.711589 1.36816C0.711588 2.10454 1.30854 2.7015 2.04492 2.7015C2.7813 2.7015 3.37826 2.10454 3.37826 1.36816C3.37826 0.631784 2.7813 0.0348308 2.04492 0.0348307C1.30854 0.0348306 0.711589 0.631784 0.711589 1.36816ZM133.959 1.36818C133.959 2.10456 134.556 2.70151 135.292 2.70151C136.028 2.70151 136.625 2.10456 136.625 1.36818C136.625 0.631796 136.028 0.0348424 135.292 0.0348423C134.556 0.0348423 133.959 0.631796 133.959 1.36818ZM2.04492 1.61816L135.292 1.61818L135.292 1.11818L2.04492 1.11816L2.04492 1.61816Z" fill="black"/>
              </svg>
              <h3>€{{ Details.data.orderValueTotal }}</h3>
          </div>
          <div class="sec-31">
            <h2>New Delivery Charge</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="137" height="3" viewBox="0 0 137 3" fill="none">
                <path d="M0.711589 1.36816C0.711588 2.10454 1.30854 2.7015 2.04492 2.7015C2.7813 2.7015 3.37826 2.10454 3.37826 1.36816C3.37826 0.631784 2.7813 0.0348308 2.04492 0.0348307C1.30854 0.0348306 0.711589 0.631784 0.711589 1.36816ZM133.959 1.36818C133.959 2.10456 134.556 2.70151 135.292 2.70151C136.028 2.70151 136.625 2.10456 136.625 1.36818C136.625 0.631796 136.028 0.0348424 135.292 0.0348423C134.556 0.0348423 133.959 0.631796 133.959 1.36818ZM2.04492 1.61816L135.292 1.61818L135.292 1.11818L2.04492 1.11816L2.04492 1.61816Z" fill="black"/>
              </svg>
              <h3>£ {{ Details.data.newDeliveryPrice }}</h3>
          </div>
          <div class="loader" v-if="Loading">
            <loader-comp></loader-comp>
          </div>
          <div id="sub-btn" @click="submit">
            Confirm
        </div>
        </div>
        <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to change order address"></error-comp>
        </div>
        <div class="message-comp" v-if="Message==69">
            <success-message msg="Address changed successfully"></success-message>
        </div>
    </div>
</template>

<script>
import LoaderComp from '../general/loader-comp.vue'
import ErrorComp from '../messages/error-comp.vue'
import ServerCrash from '../messages/server-crash.vue'
import SuccessMessage from '../messages/success-comp.vue'
export default {
    components:{LoaderComp,ErrorComp,ServerCrash,SuccessMessage},
    data(){
        return{
            loading:false,
            message:0
        }
    },
    computed:{
        OrderNo(){
            return this.$route.params.id
        },
        Details(){
            return this.$store.getters['orders/getNewDetails']
        },
        Loading(){
            return this.loading
        },
        Message(){
            return this.message;
        },
    },
    methods:{
        close(){
            this.$store.commit('orders/setModal',{value:false})
        },
        async submit(){
            this.loading = true
            let res = await this.$store.dispatch('orders/editAddress',{
                order:this.Details.orderId,
                address:this.Details.data.orderNewAddress.address,
                landmark:this.Details.data.orderNewAddress.landmark
            })
            this.loading = false
            if(res==200){
                
                this.message=69
                setTimeout(() => {
                    this.$store.commit('orders/setModal',{value:false})
                    this.$store.commit('orders/setTrackOrder',{value:1})
                },1500)
            }
            else if(res==500){
                this.message=1;
                setTimeout(()=>{
                    this.message=0
                    this.$store.commit('orders/setModal',{value:false})
                },1500)
                
            }
            else{
                setTimeout(()=>{
                    this.message=0;
                    this.$store.commit('orders/setModal',{value:false})
                },1500)
            }
        },
        callDriver(val){
            if(val){
            const phoneNumber =val; // Replace with the actual mobile number
            const telUrl = `tel:${phoneNumber}`;
            window.location.href = telUrl;
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #modal{
        width:90vw;
        height:auto;
        background: #FFF;
        position: fixed;
        z-index: 1;
        margin-left:5vw;
        margin-right:5vw;
        margin-top:-18vh;
        border-radius: 6px;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        overflow-x:hidden;
        padding-top:1.5vh;
        padding-bottom:1.5vh;
    } 
    .message-comp{
        width:90vw;
        display: flex;
        justify-content: center;
        margin-top:15vh;
    }
    #loader{
        width:90vw;
        display: flex;
        justify-content: center;
    }
    #modal-header{
        margin-top:4vw;
        width:90vw;
        margin-bottom:8vh;
    }
    #modal-header h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
float:left;
margin-left:4vw;
word-wrap: break-word;
    }
    #modal-header svg{
          float:left;
          margin-left:77vw;
          margin-top:-2.5vh;
    }
    #section1{
        width:88vw;
        margin-left:1vw;
        margin-right:1vw;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #orderNumber{
        display: inline-flex;
        width:80vw;
        padding-left:4vw;
        padding-right:4vw;
        margin-top:1vh;
        margin-bottom:1vh
    }
#orderNumber svg{
        margin-left:1vw;
        margin-right:1vw;
        margin-top:6px;
    }
    #orderNumber h2{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
    }
    #orderNumber h3{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        word-wrap: break-word;
    }
    .orderAddress{
        width:80vw;
        margin-top:2vh;
        margin-bottom:2vh;
        padding-left:4vw;
        padding-right:4vw
    }
    .orderAddress h2{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        word-wrap: break-word;
    }
    .orderAddress h3{
        color: var(--Brand-color-2, #413F3F);
    
        /* Sub text */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top:5px;
        word-wrap: break-word;
    }
    #section2{
        width:88vw;
        margin-left:1vw;
        margin-right: 1vw;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #sec-21{
        padding-top:2vh;
        padding-bottom:2vh;
    }
    #sec-21 img{
        width:57px;
        height:57px;
        border-radius: 57px;
        margin-left:6vw;
        margin-top:-1.5vh;
    }
    #sec-21 svg{
        margin-left:6vw;
        margin-top:-1.5vh;
    }
    #sec-21 h3{
      color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-right:18vw;
float:right;
margin-top:-8vh;
word-wrap:break-word
    }
#sec-22 h3{
    color: var(--Brand-color-2, #413F3F);

    /* Sub text */
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:4vw;
    margin-top:1vh;
    word-wrap:break-word
}
#call-driver{
    border-radius: 8px;
background: #25AD34;
width:99px;
height:41px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 41px;
float:right;
margin-top:-6vh;
margin-right:8vw;
}
#section3{
    width:88vw;
    margin-left:1vw;
        margin-right: 1vw;
    height:auto;
    margin-top:0.75vh;
    margin-bottom:0.75vh;
    border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
}
.sec-31{
    display: inline-flex;
    width:80vw;
    padding-left:4vw;
    padding-right:4vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.sec-31 h2{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.sec-31 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:9px;
}
.sec-31 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
#sub-btn{
    width:50vw;
    margin-left:20vw;
    height:45px;
    border-radius: 8px;
background: #25AD34;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 45px;
margin-top:2vh;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #modal{
        margin-top:4vh;
        position:fixed;
        z-index:1;
        width:70vw;
        height:auto;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        background: #fff;
        margin-left:15vw;
    }
    .message-comp{
        width:70vw;
        display: flex;
        justify-content: center;
        margin-top:15vh;
    }
    #loader{
        width:70vw;
        display: flex;
        justify-content: center;
    }
    #modal-header{
        margin-top:3vh;
        width:70vw;
        margin-bottom:8vh;
    }
    #modal-header h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
float:left;
margin-left:2vw;
word-wrap: break-word;
    }
    #modal-header svg{
          float:left;
          margin-left:63vw;
          margin-top:-2.5vh;
    }
    #section1{
        width:68vw;
        margin-left:1vw;
        margin-right:1vw;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #section2{
        width:68vw;
        margin-left: 1vw;
        margin-right:1vw ;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #orderNumber{
        display: inline-flex;
        width:60vw;
        padding-left:4vw;
        padding-right:4vw;
        margin-top:.5vh;
        margin-bottom:.5vh;
        
    }
#orderNumber svg{
        margin-left:1vw;
        margin-right:1vw;
        margin-top:6px;
    }
    #orderNumber h2{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
    }
    #orderNumber h3{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        word-wrap: break-word;
    }
    .orderAddress{
        width:60vw;
        margin-top:2vh;
        margin-bottom:2vh;
        padding-left:4vw;
        padding-right:4vw
    }
    .orderAddress h2{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
    }
    .orderAddress h3{
        color: var(--Brand-color-2, #413F3F);
    
    /* Sub text */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top:5px;
    word-wrap: break-word;
    }
    #sec-21{
        padding-top:2vh;
        padding-bottom:2vh;
    }
    #sec-21 img{
        width:77px;
        height:77px;
        border-radius: 67px;
        margin-left:4vw;
        margin-top:-1.5vh;
    }
    #sec-21 svg{
        margin-left:4vw;
        margin-top:-1.5vh;
    }
    #sec-21 h3{
      color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right:12vw;
float:right;
margin-top:-8vh;
word-wrap: break-word;
    }
#sec-22 h3{
    color: var(--Brand-color-2, #413F3F);

    /* Sub text */
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:4vw;
    margin-top:-1vh;
    word-wrap: break-word;
}
#call-driver{
    border-radius: 8px;
background: #25AD34;
width:129px;
height:51px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 51px;
float:right;
margin-top:-4vh;
margin-right:5vw;
}
#section3{
    width:68vw;
    margin-left:1vw;
    margin-right:1vw;
    height:auto;
    margin-top:0.75vh;
    margin-bottom:0.75vh;
    border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
}
.sec-31{
    display: inline-flex;
    width:60vw;
    padding-left:4vw;
    padding-right:4vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.sec-31 h2{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
}
.sec-31 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:11px;
}
.sec-31 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
}
#sub-btn{
    width:30vw;
    margin-left:20vw;
    height:51px;
    border-radius: 8px;
background: #25AD34;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: 51px;
margin-top:2vh;
}

}
@media only screen and (min-width:961px){
    #modal{
        margin-top:-2vh;
        position:absolute;
        z-index:1;
        width:40vw;
        height:auto;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        background: #FFF;
        margin-left:30vw;
    }
    .message-comp{
        width:40vw;
        display: flex;
        justify-content: center;
        margin-top:15vh;
    }
    #loader{
        width:40vw;
        display: flex;
        justify-content: center;
    }
    #modal-header{
        margin-top:3vh;
        width:40vw;
        margin-bottom:8vh;
    }
    #modal-header h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
float:left;
margin-left:2vw;
    }
    #modal-header svg{
          float:left;
          margin-left:35vw;
          margin-top:-2vh;
          cursor: pointer;
    }
    #section1{
        width:38vw;
        margin-left:1vw;
        margin-right:1vw;
        height:auto;
        margin-top:10.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #orderNumber{
        display: inline-flex;
        width:36vw;
        padding-left:1vw;
        padding-right:1vw;
        margin-top:.5vh;
        margin-bottom:.5vh;
        justify-content: center;
        
    }
#orderNumber svg{
        margin-left:1vw;
        margin-right:1vw;
        margin-top:6px;
    }
    #orderNumber h2{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
    }
    #orderNumber h3{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        word-wrap: break-word;
    }
    .orderAddress{
        width:36vw;
        margin-top:2vh;
        margin-bottom:2vh;
        padding-left:1vw;
        padding-right:1vw;
    }
    .orderAddress h2{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
    }
    .orderAddress h3{
        color: var(--Brand-color-2, #413F3F);
    
        /* Sub text */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top:5px;
        word-wrap: break-word;
    }
    #section2{
        width:38vw;
        margin-left:1vw;
        margin-right:1vw;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
    }
    #sec-21{
        padding-top:2vh;
        padding-bottom:2vh;
    }
    #sec-21 img{
        width:67px;
        height:67px;
        border-radius: 67px;
        margin-left:1.5vw;
        margin-top:-1.5vh;
    }
    #sec-21 svg{
        margin-left:1.5vw;
        margin-top:-1.5vh;
    }
    #sec-21 h3{
      color: var(--Brand-color-2, #413F3F);
/* Sub text */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:9vw;
float:left;
margin-top:-7vh;
word-wrap: break-word;
    }
#sec-22 h3{
    color: var(--Brand-color-2, #413F3F);

    /* Sub text */
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:1.5vw;
    margin-top:-.34vh;
    word-wrap: break-word;
}
#call-driver{
    display: none;
}
#section3{
    width:38vw;
        margin-left:1vw;
        margin-right:1vw;
        height:auto;
        margin-top:0.75vh;
        margin-bottom:0.75vh;
        border-radius: 4px;
background: #fff;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
padding-top:1vh;
padding-bottom:1vh;
}
.sec-31{
    display: inline-flex;
    width:36vw;
    padding-left:1.5vw;
    padding-right:1.5vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.sec-31 h2{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.sec-31 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:11px;
}
.sec-31 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
#sub-btn{
    width:10vw;
    margin-left:14vw;
    height:51px;
    border-radius: 8px;
background: #25AD34;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: 51px;
cursor: pointer;
margin-top:2vh;
}
}
</style>