<template>
    <div id="payments-landing">
        <dues-modal v-if="Modal"></dues-modal>
        <confirm-modal v-if="ConfirmModal"></confirm-modal>
        <outstanding-payments></outstanding-payments>
        <billing-comp></billing-comp>
    </div>
</template>

<script>
import OutstandingPayments from './outstanding-payments.vue'
import BillingComp from './billing-comp.vue'
import ConfirmModal from './modals/confirmation-modal.vue'
import DuesModal from './modals/dues-modal.vue'
import Cookies from 'js-cookie'
export default {
    components:{OutstandingPayments,BillingComp,ConfirmModal,DuesModal},
    computed:{
        Modal(){
            return this.$store.getters['payments/getModal']
        },
        ConfirmModal(){
            return this.$store.getters['payments/getConfirmationModal']
        }
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#payments-landing{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden ;
}
#payments-landing::-webkit-scrollbar{
    display: none;
}
@media only screen and (max-width:480px){
    #payments-landing{
        padding-top:1.9vh;
        padding-bottom:1vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #payments-landing{
        padding-top:1.9vh;
        padding-bottom:1vh;
    }
}
@media only screen and (min-width:961px){
    #payments-landing{
        padding-top:1.9vh;
        padding-bottom:2vh;
    }
}
</style>