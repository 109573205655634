<template>
    <div id="edit-delivery">
        <div id="row-1">
            <div id="row1-1">
                <back-button @click="back"></back-button>
                <h2>Edit Name</h2>
            </div>
            <div id="row1-2">
                <h2 id="row1-2-title">Order ID</h2>
                <div id="dash"></div>
                <h2 id="row1-2-value">{{ OrderId }}</h2>
            </div>
        </div>
        <div id="row-2" v-if="Message==0">
            <h2 id="value">{{ Name.name }}</h2>
            <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Edit Customer Name" v-model="name" >
            <h3 v-if="Error" id="error-message">This field can not be empty</h3>
            <div id="loader" v-if="Loading">
                <loader-comp></loader-comp>
           </div>
            <submit-button Text="Edit" @click="submit"></submit-button>
        </div>
        <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to change customer name"></error-comp>
        </div> 
        <div class="message-comp" v-if="Message==3">
           <success-message msg="Customer name changed successfully"></success-message>
        </div> 
    </div>
</template>

<script>
import BackButton from '../general/back-button.vue'
import SubmitButton from '../general/submit-button.vue'
import LoaderComp from '../general/loader-comp.vue'
import ErrorComp from '../messages/error-comp.vue'
import ServerCrash from '../messages/server-crash.vue'
import SuccessMessage from '../messages/success-comp.vue'
export default {
    components:{BackButton,SubmitButton,LoaderComp,ErrorComp,ServerCrash,SuccessMessage},
    data(){
        return{
            name:'',
            error:false,
            message:0
        }
    },
    methods:{
        back(){
        this.$store.commit('orders/setTrackOrder',{value:1})
       },
       async submit(){
        if(this.name==''){
            this.error=true
        }
        else{
            this.$store.commit('user/setLoading',{value:true})
            let res = await this.$store.dispatch('orders/editName',{order:this.$route.params.id,name:this.name})
            this.$store.commit('user/setLoading',{value:false})
            if(res==200){
                let val = this.$store.getters['orders/getCurrOrder']
                val['name'] =  this.name
                this.$store.commit['orders/setCurrrOrder',{value:val}]
                this.name=''
                this.message=3;
                setTimeout(()=>{
                    this.$store.commit('orders/setTrackOrder',{value:1})
                },1500)
            }
            else if(res==500){
                this.message=1;
                setTimeout(()=>{
                    this.message=0
                },1500)
            }
            else{
                this.message=2;
                setTimeout(()=>{
                    this.message=0
                },1500)
            }
        }

       }
    },
    computed:{
        OrderId(){
            return this.$route.params.id
        },
        Name(){
            return this.$store.getters['orders/getCurrOrder']
        },
        Error(){
            return this.error
        },
        Loading(){
            return this.$store.getters['user/getLoading']
        },
        Message(){
            return this.message
        }
    },
    

}
</script>

<style scoped>
#edit-delivery{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden;
    padding-bottom:2.75vh;
}
#edit-delivery::-webkit-scrollbar{
    display: none;
}
.message-comp{
    width:100vw;
    margin-top:15vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width:480px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:10px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dash{
       height:1px;
       width:20vw;
       background: #8D8D8D;
       margin-left:1vw;
       margin-right:1vw;
       margin-top:10px;
    }
    #row-2{
        margin-top:7vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:6vw;
margin-bottom:1vh;
    }
    #loader{
        width:auto;
        margin-top:1.5vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:6.75vw;
        margin-top:-19px;
        margin-bottom:10px;
    }
}
@media only screen and (min-width:481px) and (max-width: 960px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
        height:1px;
        width:25vw;
        background: #8D8D8D;
        margin-left:.5vw;
        margin-right:.5vw;
        margin-top:10px;
     }
     #row-2{
        margin-top:8vh;
     }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:20vw;
margin-bottom:1vh;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;

    }
    .error-message{
        font-family: Inter;
        font-size:15px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:20.75vw;
        margin-top:-16px;
        margin-bottom:12px;
    }
}
@media only screen and (min-width:960px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:40vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #edit-delivery{
        padding-bottom:3vh;
        overflow-y:auto;
    }
    #edit-delivery::-webkit-scrollbar{
        display: none;
    }
    #row-2{
        margin-top:10vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:57px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:57px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left:36vw;
margin-bottom:3vh;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:35.75vw;
        margin-top:-16px;
    }
}
</style>