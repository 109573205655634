<template>
    <div id="map" class="active-map"></div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    data() {
    return {
      map: null,
      directionsService:null,
      directionsRenderer:null,
      destinationMarker:null,
      sourceMarker:null,
    };
  },
  methods:{
  initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.center, // Initial map center
        zoom: 12,
        options:{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
          // {
          //   featureType: 'all',
          //   elementType: 'geometry',
          //   stylers: [{ color: '#d7d7d7' }], // Set the background color here (blue in this case)
          // },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#FFFFFF',weight:3,gama:0.5,saturation: -50 }] // Set the road color here (blue in this case)
          },
          {
            featureType: 'label',
            elementType: 'labels.text.fill',
            stylers: [
              { visibility: 'off' }    // Hide text labels
            ]
          },
          
        ],
      }
      });
      this.directionsService = new window.google.maps.DirectionsService();
      this.directionsRenderer = new window.google.maps.DirectionsRenderer({
        map: this.map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#1CB32B', // Change the line color to blue
          strokeWeight: 4,     // Change the line thickness
        },
      });
      this.destinationMarker = new window.google.maps.Marker({
        position: this.destination,
        map: this.map,
        icon: {
          url: 'https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2Fred-drop.png?alt=media&token=ff9a45c4-f9e9-4704-be05-e7f544793e3d', // Custom destination marker
          scaledSize: new window.google.maps.Size(38, 38),
        },
      });
      this.sourceMarker = new window.google.maps.Marker({
        position: this.source,
        map: this.map,
        icon: {
          url: 'https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FOUTLET.png?alt=media&token=5ed762d3-da1e-4c53-9b49-1c2824b04220', // Custom destination marker
          scaledSize: new window.google.maps.Size(38, 38),
        },
      });
    },
    updateRoute() {
      const request = {
        origin: this.source, // Initial origin coordinates (point A)
        destination: this.destination, // Updated destination coordinates (point B)
        travelMode: 'DRIVING',
      };

      this.directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.directionsRenderer.setDirections(result);
        }
      });
    }
},
computed:{
  center(){
    return this.$store.getters['orders/getOutletCoordinates']
  },
  destination(){
    return this.$store.getters['orders/getOutletCoordinates']
  },
  source(){
    return this.$store.getters['orders/getCustomerCoordinates']
  }
},
  async mounted() {
    if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
    await this.initMap();
    await this.updateRoute()
  },
}
</script>

<style scoped>
@media only screen and (max-width:480px){
     .active-map{
        width:94vw;
        height:40vh;
        border-radius: 10px;
        margin-top:2vh;
        margin-left:3vw;
     }
     #map{
        width:94vw;
        height:40vh;
     }
}
@media only screen and (min-width:481px) and (max-width:960px){
    .active-map{
        width:90vw;
        height:40vh;
        border-radius: 10px;
        margin-top:2.5vh;
        margin-left:5vw;
     }
     #map{
        width:90vw;
        height:40vh;
     }
}
@media only screen and (min-width:961px){
    .active-map{
        width:60vw;
        margin-left:2vw;
        height:60vh;
        border-radius: 10px;
     }
     #map{
        width:60vw;
        height:60vh;
     }
}
</style>