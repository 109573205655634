const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
export default {
  async outstandingPayments() {
    try {
      let reqUrl = `${hostName}/outlet/payments`;
      let reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.orders };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async billing(context, payload) {
    try {
      let reqBody = { date: payload.value };
      let reqUrl = `${hostName}/outlet/billing`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.orders };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async settle(context, payload) {
    try {
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let reqUrl = `${hostName}/outlet/settle-payment`;
      let reqBody = {
        orderId: payload.value,
      };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
};
