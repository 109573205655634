<template>
    <div id="confirm">
        <div id="confirm-loader" v-if="Loading">
            <loader-comp></loader-comp>
        </div>
        <div v-if="Message==0">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" id="close" @click="close">
                <path d="M26.6667 0H3.33333C2.44928 0 1.60143 0.351189 0.976311 0.976311C0.351189 1.60143 0 2.44928 0 3.33333V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V3.33333C30 2.44928 29.6488 1.60143 29.0237 0.976311C28.3986 0.351189 27.5507 0 26.6667 0ZM21 23.3333L15 17.3333L9 23.3333L6.66667 21L12.6667 15L6.66667 9L9 6.66667L15 12.6667L21 6.66667L23.3333 9L17.3333 15L23.3333 21L21 23.3333Z" fill="#D9D9D9"/>
              </svg>
            <h3 id="mod-title">Received Payment</h3>
            <div class="mod-btn-1" @click="confirm">Yes, Confirm</div>
            <div class="mod-btn-2" @click="reject">No, Not Received</div>
        </div>
        <div class="message-comp-confirm" v-if="Message==1">
            <success-message msg="Payment settled"></success-message>
        </div>
        <div class="message-comp-confirm" v-if="Message==2">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp-confirm" v-if="Message==3">
            <error-message msg="Failed to settle payment"></error-message>
        </div>
    </div>
</template>

<script>
import LoaderComp from '../../general/loader-comp.vue'
import ServerCrash from '../../messages/server-crash.vue'
import ErrorMessage from '../../messages/error-comp.vue'
import SuccessMessage from '../../messages/success-comp.vue'
export default {
    components:{LoaderComp,ServerCrash,ErrorMessage,SuccessMessage},
    data(){
        return {
            loader:false,
            message:0
        }   
    },
    computed:{
        Loading(){
            return this.loader
        },
        Message(){
            return this.message
        }
    },
    methods:{
        close(){
            this.$store.commit('payments/setConfirmationModal',{value:false})
        },
        async confirm(){
            // send post request
            let order = this.$store.getters['payments/getCurrPayment']
            this.loader=true
            let res = await this.$store.dispatch('payments/settle',{value:order.orderId})
            this.loader=false
            if(res==200){
                let orders = this.$store.getters['payments/getOutstanding']
                const indicesToFilter = [order.index];
                const filteredArray = orders.filter((_, index) => !indicesToFilter.includes(index));
                this.$store.commit('payments/setOutstanding',{value:filteredArray})
                this.message=1
                setTimeout(()=>{this.$store.commit('payments/setConfirmationModal',{value:false})},1500)
            }
            else if(res==500){
                this.message=2
                setTimeout(()=>{
                    this.message=0
                },1500)
            }
            else{
                this.message=3
                setTimeout(()=>{
                    this.message=0
                },1500)
            }
        },
        reject(){
           this.$store.commit('payments/setModal',{value:true})
           this.$store.commit('payments/setConfirmationModal',{value:false})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.message-comp-confirm{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:480px){
    #confirm{
        width:90vw;
        height:auto;
        margin-top:3vh;
        margin-left:5vw;
        background: #FFF;
        position: fixed;
        z-index: 1;
        border-radius:6px;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        padding-bottom:2vh;
    }
    #confirm-loader{
        width:90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:7vh;
    }
    #close{
        margin-top:-0.35vh;
        float:right;
        margin-right:4vw;
    }
    #mod-title{
        color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-top:2vh;
    }
.mod-btn-1{
    width:50vw;
    margin-left:20vw;
    height:51px;
    border-radius: 8px;
background: #25AD34;
margin-top:3vh;
margin-bottom:1.5vh;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 51px;

}
.mod-btn-2{
    width:50vw;
    margin-left:20vw;
    height:51px;
    border-radius: 8px;
background: var(--Button-color-2, #5C5C8B);
margin-top:1.5vh;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 51px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #confirm{
        width:70vw;
        margin-left:15vw;
        height:auto;
        border-radius:10px;
        background: #FFF;
        position: fixed;
        z-index: 1;
        margin-top:4vh;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        padding-bottom:2vh
    }
    #confirm-loader{
        width:70vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:7vh;
    }
    #close{
        margin-top:-1vh;
        float:right;
        margin-right:4vw;
    }
    #mod-title{
        color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-top:2vh;
    }
    .mod-btn-1{
        width:30vw;
        margin-left:20vw;
        height:61px;
        border-radius: 8px;
    background: #25AD34;
    margin-top:3vh;
    margin-bottom:1.5vh;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 61px;
    
    }
    .mod-btn-2{
        width:30vw;
        margin-left:20vw;
        height:61px;
        border-radius: 8px;
    background: var(--Button-color-2, #5C5C8B);
    margin-top:1.5vh;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 61px;
    }
}
@media only screen and (min-width:961px){
    #confirm{
        width:40vw;
        margin-left:30vw;
        height:auto;
        background: #FFF;
        position: fixed;
        z-index: 1;
        border-radius:10px;
        margin-top:4vh;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.35);
        padding-bottom: 2vh;
    }
    #confirm-loader{
        width:40vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:7vh;
    }
    #close{
        margin-top:-2vh;
        float:right;
        margin-right:1vw;
        cursor: pointer;
    }
    #mod-title{
        color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-top:4vh;
    }
    .mod-btn-1{
        width:16vw;
        margin-left:12vw;
        height:55px;
        border-radius: 8px;
    background: #25AD34;
    margin-top:3vh;
    margin-bottom:1.5vh;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
cursor: pointer;
    
    }
    .mod-btn-2{
        width:16vw;
        margin-left:12vw;
        height:55px;
        border-radius: 8px;
    background: var(--Button-color-2, #5C5C8B);
    margin-top:2.5vh;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    cursor: pointer;
    }
}
</style>