import LiveOrder from "../views/home/track-delivery.vue";
import DeliveryReview from "../views/home/delivery-review.vue";
const routes = [
  {
    name: "liveOrder",
    path: "/live-order/:id",
    component: LiveOrder,
    props: true,
  },
  {
    name: "deliveryReview",
    path: "/delivery-review/:id",
    component: DeliveryReview,
    props: true,
  },
];

export default routes;
