<template>
    <div id="edit-delivery">
        <preview-modal v-if="Modal"></preview-modal>
        <div id="row-1">
            <div id="row1-1">
                <back-button @click="back"></back-button>
                <h2>Edit Address</h2>
            </div>
            <div id="row1-2">
                <h2 id="row1-2-title">Order ID</h2>
                <div id="dash"></div>
                <h2 id="row1-2-value">{{ OrderId }}</h2>
            </div>
        </div>
        <div id="row-2" v-if="Message==0">
            <h2 id="value">{{ Name.address }}</h2>
            <address-comp></address-comp>
            <!-- <h3 v-if="Error==3" class="error-message">{{ ErrorMessage }}</h3> -->
            <div id="loader" v-if="Loading">
                <loader-comp></loader-comp>
           </div>
            <submit-button Text="Edit" @click="submit"></submit-button>
        </div>
        <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to show new order preview"></error-comp>
        </div> 
        <div class="message-comp" v-if="Message==3">
            <error-comp msg="Delivery not possible on this route"></error-comp>
        </div> 
        <div class="message-comp" v-if="Message==4">
           <success-message msg="Delivery address changed successfully"></success-message>
        </div> 
    </div>
</template>

<script>
import BackButton from '../general/back-button.vue'
import SubmitButton from '../general/submit-button.vue'
import LoaderComp from '../general/loader-comp.vue'
import PreviewModal from './preview-modal.vue'
import ErrorComp from '../messages/error-comp.vue'
import ServerCrash from '../messages/server-crash.vue'
import SuccessMessage from '../messages/success-comp.vue'
import AddressComp from './edit-comp.vue'
export default {
    components:{BackButton,SubmitButton,LoaderComp,PreviewModal,ErrorComp,ServerCrash,AddressComp,SuccessMessage},
    data(){
        return{
            address:this.$store.getters['orders/getCurrOrder'].address,
            field:0,
            message:"",
            serverMessage:0

        }
    },
    methods:{
        back(){
        this.$store.commit('orders/setTrackOrder',{value:1})
       },
       async submit(){
            let address = this.$store.getters['orders/getEditAddress']
            if(address=='' || address == 'Please enter a valid ZipCode' || address=='error'){
                this.$store.commit('orders/setEditAddress',{value:'error'})
            }
            else{
                this.$store.commit('user/setLoading',{value:true})
                let res =await this.$store.dispatch('orders/previewEditAddress',{
                     order:this.$route.params.id,
                     address:address,
                })
                this.$store.commit('user/setLoading',{value:false})
                if(res.status==200){
                    this.$store.commit('orders/setNewDetails',{value:res.data})
                    this.$store.commit('orders/setModal',{value:true})

                }
                else if(res.status==400){
                    this.serverMessage=3;
                    setTimeout(()=>{
                        this.serverMessage=0
                    },1500)
                }
                else if(res.status==500){
                    this.serverMessage=1;
                    setTimeout(()=>{
                        this.serverMessage=0
                    },1500)
                }
                else{
                    this.serverMessage=2;
                    setTimeout(()=>{
                        this.serverMessage=0
                    },1500)
                }
            }
            
       },
       async moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        if(nextInputName=='input-4'){
            await this.submit()
        }
        else{
            this.$refs[nextInputName].focus();
        }
      }
              }
    },
    computed:{
        OrderId(){
            return this.$route.params.id
        },
        Error(){
            return this.field
        },
        ErrorMessage(){
            return this.message
        },
        Name(){
            return this.$store.getters['orders/getCurrOrder']
        },
        Loading(){
            return this.$store.getters['user/getLoading']
        },
        Modal(){
            return this.$store.getters['orders/getModal']
        },
        Message(){
            return this.serverMessage
        }
    }

}
</script>

<style scoped>
#edit-delivery{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden;
    overflow-y:auto;
    padding-bottom:2.75vh;
}
#edit-delivery::-webkit-scrollbar{
    display: none;
}
.message-comp{
    width:100vw;
    margin-top:25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40vh;
}
@media only screen and (max-width:480px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:10px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dash{
       height:1px;
       width:20vw;
       background: #8D8D8D;
       margin-left:1vw;
       margin-right:1vw;
       margin-top:10px;
    }
    #row-2{
        margin-top:4vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:6vw;
margin-bottom:1vh;
    }
    #loader{
        width:auto;
        margin-top:1.5vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
 
}
@media only screen and (min-width:481px) and (max-width: 960px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row-2{
        margin-top:8vh;
     }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:20vw;
margin-bottom:1vh;
    }
    
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;

    }
}
@media only screen and (min-width:961px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:40vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #edit-delivery{
        padding-bottom:3vh;
        overflow-y:auto;
    }
    #edit-delivery::-webkit-scrollbar{
        display: none;
    }
    #row-2{
        margin-top:10vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:57px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:57px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
outline:none;
padding-left:20px;
margin-bottom:18.52px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #value{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left:36vw;
margin-bottom:3vh;
    }
   
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
}
</style>