import { createStore } from "vuex";
import user from "./user/index.js";
import orders from "./orders/index.js";
import payments from "./payments/index.js";

export default createStore({
  modules: {
    user: user,
    orders: orders,
    payments: payments,
    url: "https://seal-app-brp6e.ondigitalocean.app/api/v1",
  },
});
