<template>
    <div id="review-modal">
        <div id="modal-back">
            <back-button @click="back"></back-button>
        </div>
    <h2 id="review-payment">Review Payment</h2>
    <div class="detail1">
        <h2>Order No.</h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="146" height="4" viewBox="0 0 146 4" fill="none">
            <path d="M0.147135 2.07422C0.147135 2.8106 0.744089 3.40755 1.48047 3.40755C2.21685 3.40755 2.8138 2.8106 2.8138 2.07422C2.8138 1.33784 2.21685 0.740885 1.48047 0.740885C0.744089 0.740885 0.147135 1.33784 0.147135 2.07422ZM142.711 2.07421C142.711 2.81059 143.308 3.40754 144.044 3.40754C144.781 3.40754 145.378 2.81059 145.378 2.07421C145.378 1.33783 144.781 0.740873 144.044 0.740873C143.308 0.740873 142.711 1.33783 142.711 2.07421ZM1.48047 2.32422L144.044 2.32421L144.044 1.82421L1.48047 1.82422L1.48047 2.32422Z" fill="black"/>
          </svg>
          <h3>{{ value.orderNo }}</h3>
    </div>
    <div class="detail1">
       <h2>Amount Charged</h2>
       <svg xmlns="http://www.w3.org/2000/svg" width="146" height="4" viewBox="0 0 146 4" fill="none">
        <path d="M0.147135 2.07422C0.147135 2.8106 0.744089 3.40755 1.48047 3.40755C2.21685 3.40755 2.8138 2.8106 2.8138 2.07422C2.8138 1.33784 2.21685 0.740885 1.48047 0.740885C0.744089 0.740885 0.147135 1.33784 0.147135 2.07422ZM142.711 2.07421C142.711 2.81059 143.308 3.40754 144.044 3.40754C144.781 3.40754 145.378 2.81059 145.378 2.07421C145.378 1.33783 144.781 0.740873 144.044 0.740873C143.308 0.740873 142.711 1.33783 142.711 2.07421ZM1.48047 2.32422L144.044 2.32421L144.044 1.82421L1.48047 1.82422L1.48047 2.32422Z" fill="black"/>
      </svg>
      <h3>{{ value.orderValue }}</h3>
    </div>
    <div class="detail1">
       <h2>Mode of Payment</h2>
       <svg xmlns="http://www.w3.org/2000/svg" width="146" height="4" viewBox="0 0 146 4" fill="none">
        <path d="M0.147135 2.07422C0.147135 2.8106 0.744089 3.40755 1.48047 3.40755C2.21685 3.40755 2.8138 2.8106 2.8138 2.07422C2.8138 1.33784 2.21685 0.740885 1.48047 0.740885C0.744089 0.740885 0.147135 1.33784 0.147135 2.07422ZM142.711 2.07421C142.711 2.81059 143.308 3.40754 144.044 3.40754C144.781 3.40754 145.378 2.81059 145.378 2.07421C145.378 1.33783 144.781 0.740873 144.044 0.740873C143.308 0.740873 142.711 1.33783 142.711 2.07421ZM1.48047 2.32422L144.044 2.32421L144.044 1.82421L1.48047 1.82422L1.48047 2.32422Z" fill="black"/>
      </svg>
      <h3>{{ value.paymentMode }}</h3>
    </div>
    <!-- <div class="detail2">
        <h2>Order Items</h2>
        <h3>{{ value.orderItems }}</h3>
    </div>
    <div class="detail2">
        <h2>Delivery Address</h2>
        <h3>{{ value.deliveryAddress }}</h3>
    </div> -->
    <div class="detail2" v-if="value.category == 'Food-Order'">
        <h2>Customer Name</h2>
        <h3>{{ value.customerName }}</h3>
    </div>
    <div class="detail2" v-if="value.category=='Food-Order'">
        <h2>Delivered By</h2>
        <h3>{{ value.deliveredBy }}</h3>
    </div>
    </div>
</template>

<script>
import BackButton from '../general/back-button.vue'
export default {
    components:{BackButton},
    methods:{
        back(){
            this.$store.commit('payments/setReviewModal',{value:false})
        }
    },
    props:['value']
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #review-modal{
        width:90vw;
        height:auto;
        min-height:40vw;
        border-radius: 6px;
background: #FFF;
position: fixed;
z-index: 1;
margin-left:5vw;
margin-top:8vh;
box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.45);
overflow-x:hidden ;
padding-top:2vh;
padding-bottom:2vh;

    }
#modal-back{
    margin-left:2vw;
    margin-top:2vh;
}
#review-payment{
    color: var(--Brand-color-2, #282828);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:3vh;
margin-left:3vw;
margin-bottom:5vh
}
.detail1{
    display: inline-flex;
    width:90vw;
    padding-left:3.5vw;
    padding-right:1.5vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.detail1 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:6px;
}
.detail1 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
word-wrap: break-word;
}
.detail1 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
}
.detail2 {
    width:90vw;
    margin-top:2vh;
    margin-bottom:2vh;
    padding-left:4vw;
    padding-right:4vw
}
.detail2 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
word-wrap: break-word;
}
.detail2 h3{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:5px;
word-wrap: break-word;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #review-modal{
        margin-top:15vh;
        position:fixed;
        z-index:1;
        width:70vw;
        height:auto;
        min-height:40vh;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.45);
        border-radius: 10px;
        background: #FFF;
        margin-left:15vw;
        overflow-x:hidden;
        padding-top:2vh;
        padding-bottom:2vh;
    }
    #modal-back{
        margin-top:2vh;
        margin-left:2vw;
    }
    #review-payment{
        color: var(--Brand-color-2, #282828);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top:2vh;
    margin-left:3vw;
    margin-bottom:2vh
  }
  .detail1{
    display: inline-flex;
    width:70vw;
    padding-left:3.5vw;
    padding-right:1.5vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.detail1 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:10px;
}
.detail1 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.detail1 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.detail2 {
    width:70vw;
    margin-top:2vh;
    margin-bottom:2vh;
    padding-left:4vw;
    padding-right:4vw
}
.detail2 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.detail2 h3{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:5px;
}
}
@media only screen and (min-width:961px){
#review-modal{
    margin-top:10vh;
    position:fixed;
    z-index:1;
    width:40vw;
    min-height:40vh;
    height:auto;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    background: #FFF;
    margin-left:30vw;
    overflow-x: hidden;

}
#modal-back{
    margin-top:2vh;
    margin-left:1vw;
}
#review-payment{
    color: var(--Brand-color-2, #282828);
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left:1.5vw;
margin-top:3vh;
margin-bottom:1vh;
}
.detail1{
    display: inline-flex;
    width:40vw;
    padding-left:3.5vw;
    padding-right:1.5vw;
    margin-top:1vh;
    margin-bottom:1vh
}
.detail1 svg{
    margin-left:1vw;
    margin-right:1vw;
    margin-top:10px;
}
.detail1 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.detail1 h3{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.detail2 {
    width:40vw;
    margin-top:2vh;
    margin-bottom:2vh;
    padding-left:3.5vw;
    padding-right:4vw
}
.detail2 h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.detail2 h3{
    color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:5px;
}
}
</style>