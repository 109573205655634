<template>
  <div id="container">
    <router-view></router-view>
  </div>
</template>

<script setup>
// Import the functions you need from the SDKs you need
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import { useStore } from 'vuex';
import Cookies from "js-cookie";
const store = useStore();
const messaging = getMessaging();
navigator.serviceWorker.register('firebase-messaging-sw.js').then(function(reg) {
//   console.log('started with the registration')
//  if(reg.installing) {
//         console.log('Service worker installing');
//     } 
//     if(reg.waiting) {
//         console.log('Service worker installed');
//     } 
    if(reg.active) {
      onMessage(messaging, (payload) => {
      store.commit('user/setNotificationData',{value:payload.notification})
      store.commit('user/setNotification',{value:true})
});  
      getToken(messaging, { vapidKey: 'BA8-75eaYdvb_nPTHnHUqbohiWpgxprTxti-IOEdqyJHGPdGQ0j8V1kg2bzhxiPanjlasIGzJ8axEJRqe_7eO4s'}).then(async (currentToken) => {
        Cookies.remove('fcm')
        if (currentToken) {
    // Send the token to your server and update the UI if necessary
    await store.dispatch('user/putToken',{value:currentToken})
    Cookies.set('fcm',currentToken)
  } 
  // else {
  //   console.log('No registration token available. Request permission to generate one.');
  // }
}).catch((err) => {
  return err
 // console.log('An error occurred while retrieving token. ', err);
});
    }
})
</script>

<style scoped>
#container{
  position: fixed;
  top:0;
  left:0;
  overflow-x:hidden;
  overflow-y:auto ;
  width:100vw;
  height:100vh;
}
#container::-webkit-scrollbar{
  display: none;
}
</style>