<template>
   <div id="active-order">
    <div id="assign-heading">
        <h2>Active Deliveries</h2>
    </div>
    <div id="table">
        <div id="heading">
            <h2 class="heading-text">Driver Name</h2>
            <h2 class="heading-text">Delivery Charge</h2>
            <h2 class="heading-text">Payment Mode</h2>
            <h2 class="heading-text">Order ID</h2>
            <h2 class="heading-text">Destination</h2>
            <h2 class="heading-text">Date</h2>
            <h2 class="heading-text"></h2>
        </div>
    <div id="underline"></div>
    <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
    </div>
    <div v-else>
        <div id="items" v-if="Show">
            <div id='content' v-for="order,index in Orders" :key="index">
               <div class="item">
                   <h2 class="item-text" v-if="order.driverName">{{ order.driverName}}</h2>
                   <h2 class="item-text" v-else>Not Assigned</h2>
                   <h2 class="item-text">£{{ order.delivery_price}}</h2>
                   <h2 class="item-text">{{ order.mode_of_payment }}</h2>
                   <h2 class="item-text">{{order.id}}</h2>
                   <h2 class="item-text">{{ order.address}}</h2>
                   <h2 class="item-text">{{ order.createdAt }}</h2>
                   <div id="details" @click="redirect(order.id)">
                       Track
                   </div>
               </div>
               <div class="underline1">
                   
               </div>
            </div>
           </div>
           <div v-else>
            <div v-if="Message==1" class="message-comp">
                <empty-list msg="No active order"></empty-list>
            </div>
            <div v-if="Message==2" class="message-comp">
                <server-crash msg="Something went wrong at our end"></server-crash>
            </div>
            <div v-if="Message==3" class="message-comp">
                <error-comp msg="Failed to fetch orders"></error-comp>
            </div>
        </div>
    </div>
    </div>
   </div>
</template>

<script>
import LoaderComp from '../general/loader-comp.vue'
import EmptyList from '../messages/empty-comp.vue'
import ServerCrash from '../messages/server-crash.vue'
import ErrorComp from '../messages/error-comp.vue'
import Cookies from 'js-cookie'
export default {
    components:{LoaderComp,EmptyList,ServerCrash,ErrorComp},
    data(){
    return{
        orders:[],
        message:0
    }
 },
 computed:{
    Orders(){
        return this.orders
    },
    Show(){
     try{
        let val = Object.keys(this.orders).length
        if(val>0) return true
        else return false
     }
     catch(err){
        return false
     }
    },
    Loading(){
        return this.$store.getters['user/getLoading']
    },
    Message(){
        return this.message
    }
 },
 methods:{
    redirect(val){
        this.$router.push({name:"liveOrder",params:{id:val}})
    }
 },
 async mounted(){
    if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
    // retrieve the fcm token here and then send it to backend
    this.$store.commit('user/setLoading',{value:true})
    let res = await this.$store.dispatch('orders/activeOrders')
    this.$store.commit('user/setLoading',{value:false})
    if(res.status==200){
        this.orders=res.data;
        if(res.data.length==0){
        this.message=1
    }
    }
    else if(res.status==500){
        this.message=2
    }
    else{
        this.message=3
    }
 }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#active-order{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden ;
}
#active-order::-webkit-scrollbar{
    display: none;
}
.message-comp{
    width:auto;
    height:auto;
    display: flex;
    justify-content: center;
     align-items: center;
}
@media only screen and (max-width:480px){
    #active-order{
        width:100vw;
        margin-top:10px;
        height: auto;
        overflow-x:hidden ;
        padding-bottom:5.75vh;
    }
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
#table{
    width:96vw;
    height:60vh;
    margin-top:3vh;
    margin-left:2vw;
    margin-right:2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
padding-left:2vw;
padding-right:2vw;
padding-top:2vh;
padding-bottom:2vh;
overflow:auto
}
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 28vw 18vw 20vw 30vw 40vw 38vw 28vw;
    grid-gap:3vw;
    height:auto;

margin-bottom:10px;}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center;
    word-wrap: break-word;
}
#underline{
    width:230vw;
background: #8D8D8D;
height:1px;
margin-top:20px;
margin-bottom:21px;
}
.underline1{
    width:230vw;
background: #8D8D8D;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
height:55vh;
overflow: auto;
width:fit-content;
padding-top:1.5vh;
}
#items::-webkit-scrollbar{
    display: none;
}
.item{
    display:grid;
    grid-template-columns: 28vw 18vw 20vw 30vw 40vw 38vw 28vw;
    grid-gap:3vw;
    padding-bottom:0.75vh;
        padding-top:0.75vh;
}
.item-text{
    color: #413F3F;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
text-align:center;
word-wrap: break-word;
}
#details{
    border-radius: 16px;
background: #25AD34;
width:28vw;
height:32px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 32px;
margin-top:-5px;
}
#loader{
    width:94vw;
    height:45vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
#table{
    width:96vw;
    height:64vh;
    margin-top:3vh;
    margin-left:2vw;
    margin-right:2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
    padding-left:2vw;
    padding-right:2vw;
    padding-top:2vh;
    padding-bottom:2vh;
}
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 13vw 9vw 10vw 15vw 15vw 18vw 14vw ;
    grid-gap:1vw;
    height:5vh;
}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center;
    word-wrap: break-word;
}
#underline{
    width:104vw;
background: #8D8D8D;
height:1px;
margin-top:13px;
margin-bottom:21px;
}
.underline1{
    width:104vw;
background: #8D8D8D;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
    height:55vh;
    overflow: auto;
    width:fit-content;
    padding-top:1.5vh;
    }
    #items::-webkit-scrollbar{
        display: none;
    }
    .item{
        display:grid;
        grid-template-columns: 13vw 9vw 10vw 15vw 15vw 18vw 14vw ;
        grid-gap:1vw;
        padding-bottom:0.75vh;
        padding-top:0.75vh;
    }
    .item-text{
        color: #413F3F;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center;
  word-wrap: break-word;
    }
    #details{
        border-radius: 16px;
    background: #25AD34;
    width:12vw;
    height:42px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left:4vw;
    margin-top:-3px;
    }
    #loader{
        width:94vw;
        height:45vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (min-width:961px){
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
    #table{
        width:96vw;
        height:63vh;
        margin-top:3vh;
        margin-left:2vw;
        margin-right:2vw;
        border-radius: 6px;
        border: 1px solid rgba(30, 30, 30, 0.20);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
        overflow:auto;
        padding-left:2vw;
        padding-right:2vw;
        padding-top:2vh;
        padding-bottom:2vh;
    }
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 13vw 9vw 10vw 13vw 15vw 12vw 13vw ;
    grid-gap:1vw;
    height:5vh;
}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center;
    word-wrap: break-word;
    
}
#underline{
    width:92vw;
    background: #8d8d8d52;
height:1px;
margin-top:13px;
margin-bottom:21px;
}
.underline1{
    width:92vw;
    background: #8d8d8d52;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
    height:55vh;
    overflow: auto;
    width:fit-content;
    padding-top:1.5vh;
    }
    #items::-webkit-scrollbar{
        display: none;
    }
    .item{
        display:grid;
        grid-template-columns: 13vw 9vw 10vw 13vw 15vw 12vw 13vw ;
        grid-gap:1vw;
        padding-bottom:0.75vh;
        padding-top:0.75vh;
    }
    .item-text{
        color: #413F3F;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center;
  word-wrap: break-word;
    }
    #details{
        border-radius: 16px;
    background: #25AD34;
    width:8vw;
    height:42px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left:4vw;
    margin-top:-8px;
    cursor: pointer;
    }
    #loader{
        width:94vw;
        height:55vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>