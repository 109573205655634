export default {
  getPaymentFieldError(state) {
    return state.paymentFieldError;
  },
  getTrackOrder(state) {
    return state.trackOrder;
  },
  getTrackIndicator(state) {
    return state.trackIndicator;
  },
  getOutletCoordinates(state) {
    return state.outletCoordinates;
  },
  getCustomerCoordinates(state) {
    return state.customerCoordinates;
  },
  getDriverCoordinates(state) {
    return state.driverCoordinates;
  },
  getDriver(state) {
    return state.driver;
  },
  getCurrOrder(state) {
    return state.currOrder;
  },
  getModal(state) {
    return state.modal;
  },
  getNewDetails(state) {
    return state.newDetails;
  },
  getAddress(state) {
    return state.address;
  },
  getEditAddress(state) {
    return state.edit_address;
  },
  getDeliveryCost(state) {
    return state.deliveryCost;
  },
  getCostCalculated(state) {
    return state.costCalculated;
  },
  getPastModal(state) {
    return state.pastModal;
  },
  getActiveModal(state) {
    return state.activeModal;
  },
  getActiveMileStone(state) {
    return state.activeMileStone;
  },
};
