<template>
    <div id="notification-comp">
        <div id="left-notification"></div>
        <div id="content">
            <h2>{{ title }}</h2>
            <h4>{{ message }}</h4>
            <h5>{{ Time }}</h5>
        </div>

    </div>
</template>

<script>
export default {
    props:['title','message','time'],
    computed:{
        Time(){
            return this.time.split('T')[0]
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #notification-comp{
        width:auto;
      height:fit-content;
      overflow-x:hidden ;
    display: flex;
    border-radius: 4px;
border: 1px solid #25AD34;
background: var(--White, #FFF);
margin-top:1.25vh;
margin-bottom:1.25vh;
    }
#left-notification{
    background:#25AD34;
    margin-left:0vw;
    height:auto;
    width:7vw;
    margin-right:1vw;
}
#content{
    padding-top:0.5vh;
    padding-bottom:0.5vh;
    width:fill;
}
#content h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
#content h4{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:3px;
margin-bottom:3px;
}
#content h5{
    float:right;
    margin-right:2vw;
    color: var(--Brand-color-3, #8d8d8dbb);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top:7px;
    margin-bottom:7px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #notification-comp{
        width:auto;
      height:fit-content;
      overflow-x:hidden ;
    display: flex;
    border-radius: 4px;
border: 1px solid #25AD34;
background: var(--White, #FFF);
margin-top:.5vh;
margin-bottom:1.25vh;
    }
#left-notification{
    background:#25AD34;
    margin-left:0vw;
    height:auto;
    width:5vw;
    margin-right:1vw;
}
#content{
    padding-top:1vh;
    padding-bottom:1vh;
    width:fill;
}
#content h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
#content h4{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:7px;
margin-bottom:7px;
}
#content h5{
    float:right;
    margin-right:2vw;
    color: var(--Brand-color-3, #8d8d8dbb);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top:7px;
    margin-bottom:7px;
}
}
@media only screen and (min-width: 961px){
    #notification-comp{
        width:auto;
      height:fit-content;
      overflow-x:hidden ;
    display: flex;
    border-radius: 4px;
border: 1px solid #25AD34;
background: var(--White, #FFF);
margin-top:1.25vh;
margin-bottom:1.25vh;
    }
#left-notification{
    background:#25AD34;
    margin-left:0vw;
    height:auto;
    width:3vw;
    margin-right:1vw;
}
#content{
    padding-top:1vh;
    padding-bottom:1vh;
    width:fill;
}
#content h2{
    color: var(--brand-color, #25AD34);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
#content h4{
    color: var(--Brand-color-3, #8D8D8D);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:7px;
margin-bottom:7px;
}
#content h5{
    float:right;
    margin-right:2vw;
    color: var(--Brand-color-3, #8d8d8dbb);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top:7px;
    margin-bottom:7px;
}
}
</style>