<template>
    <div id="loader" v-if="Loading" style="margin-top:30vh;">
        <loader-comp></loader-comp>
    </div>
    <div v-else>
        <div id="edit-delivery" v-if="Message==0">
            <div id="row-1">
                <div id="row1-1">
                    <back-button @click="back"></back-button>
                    <h2>Edit Order</h2>
                </div>
                <div id="row1-2">
                    <h2 id="row1-2-title">Order ID</h2>
                    <div id="dash"></div>
                    <h2 id="row1-2-value">{{ OrderId }}</h2>
                </div>
            </div>
            <div id="row-2">
               <!-- <div class="entry">
                <div class="key">
                    <h2>Order Items</h2>
                <h3>{{ Details.delivery_items }}</h3>
                </div>
               </div> -->
                <div class="entry">
                    <div class="key">
                        <h2>Customer Name</h2>
                        <h3>{{ Details.customer_name }}</h3>
                    </div>
                    <div class="button" @click="editRedirect(2)">Edit</div>
                </div>
                <div class="entry">
                    <div class="key">
                        <h2>Customer Phone</h2>
                        <h3>{{ Details.customer_phone }}</h3>
                    </div>
                    <div class="button" @click="editRedirect(3)">Edit</div>
                </div>
                <div class="entry">
                    <div class="key">
                        <h2>Delivery Address</h2>
                        <h3>{{ Details.address }}</h3>
                    </div>
                    <div class="button" @click="editRedirect(4)">Edit</div>
                </div>
            </div>
        </div>
        <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
        </div>
        <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to fetch order details"></error-comp>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import BackButton from '../general/back-button.vue'
import LoaderComp from '../general/loader-comp.vue'
import ErrorComp from '../../components/messages/error-comp.vue'
import ServerCrash from '../../components/messages/server-crash.vue'
export default {
    components:{BackButton,LoaderComp,ErrorComp,ServerCrash},
    data(){
        return{
            details:{},
            message:0
        }
    },
    methods:{
        back(){
        this.$store.commit('orders/setTrackOrder',{value:0})
       },
       editRedirect(val){
        if(val==0){
            this.$store.commit('orders/setTrackOrder',{value:1})
        }
        else
        this.$store.commit('orders/setTrackOrder',{value:val})
       }
    },
    computed:{
        OrderId(){
            return this.$route.params.id
        },
        Loading(){
            return this.$store.getters['user/getLoading']
        },
        Details(){
            return this.details
        },
        Message(){
            return this.message
        }
    },
    async mounted(){
        if(!Cookies.get('authenticated')){
            this.$router.push({name:'landing'})
        }
        this.$store.commit('user/setLoading',{value:true})
        let res = await this.$store.dispatch('orders/getOrderStatus',{value:this.$route.params.id})
        this.$store.commit('user/setLoading',{value:false})
        if(res.status==200){
            this.details = res.data
            this.$store.commit('orders/setCurrrOrder',{value:{
                name:res.data.customer_name,
                phone:res.data.customer_phone,
                address:res.data.address,
                zip:res.data.zipcode,
                landmark:res.data.landmark
            }})
        }
        else if(res.status==500){
            this.message=1;
        }
        else{
            this.message=2;
        }
    }
    }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#edit-delivery{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden;
    padding-bottom:2.75vh;
}
#edit-delivery::-webkit-scrollbar{
    display: none;
}
#loader{
    width:100vw;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.message-comp{
    width:100vw;
    height: auto;
    display: flex;
    margin-top:15vh;
    justify-content: center;
}
@media only screen and (max-width: 480px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:10px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dash{
       height:1px;
       width:20vw;
       background: #8D8D8D;
       margin-left:1vw;
       margin-right:1vw;
       margin-top:10px;
    }
    #row-2{
        width:90vw;
        height:auto;
        margin-left:5vw;
        margin-top:5vh;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.30);
        background: white;
        border-radius: 17px;
        padding-top:1.5vh;
        padding-bottom:1.5vh;
    }
    
    .entry{
        width:84vw;
        padding-left:3vw;
        padding-right:3vw;
        display: grid;
        grid-template-columns: 42vw 42vw;
        margin-top:2.75vh;
        margin-bottom:2.75vh;
    }
    .key{
        width:44vw;
    }
    .key h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .key h3{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:7px;
    }
    .button{
        width:30vw;
        height:41px;
        border-radius: 8px;
        background: #25AD34;
        margin-left:10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;

text-align: center;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:25vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #row-2{
        width:70vw;
        height:auto;
        margin-left:15vw;
        margin-top:5vh;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.30);
        background: white;
        border-radius: 17px;
        padding-top:3vh;
        padding-bottom:3vh;
    }
    .entry{
        width:60vw;
        padding-left:5vw;
        padding-right:5vw;
        display: grid;
        grid-template-columns: 30vw 30vw;
        margin-top:2.75vh;
        margin-bottom:2.75vh;
    }
    .key{
        width:30vw;
    }
    .key h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .key h3{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:7px;
    }
    .button{
        width:15vw;
        height:51px;
        border-radius: 8px;
        background: #25AD34;
        margin-left:10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;

text-align: center;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
    }
}
@media only screen and (min-width:961px){
    #row-1{
        width:100vw;
        overflow-x:hidden;
        height: auto;
        padding-left:2vw;
        padding-top:2vh;
    }
    #row1-1{
        display: flex;
        align-items: center;
    }
    #row1-1 h2{
        color: #413F3F;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2{
        margin-top:2vh;
        display: flex;
        align-items: center;
        padding-left:.75vw
    }
    #row1-2-title{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #row1-2-value{
          color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:20px;
    }
    #dash{
       height:1px;
       width:40vw;
       background: #8D8D8D;
       margin-left:.5vw;
       margin-right:.5vw;
       margin-top:10px;
    }
    #edit-delivery{
        padding-bottom:3vh;
        overflow-y:auto;
    }
    #edit-delivery::-webkit-scrollbar{
        display: none;
    }
    #row-2{
        width:40vw;
        height:auto;
        margin-left:30vw;
        margin-top:5vh;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.30);
        background: white;
        border-radius: 17px;
        padding-top:3vh;
        padding-bottom:3vh;
    }
    .entry{
        width:36vw;
        padding-left:2vw;
        padding-right:2vw;
        display: grid;
        grid-template-columns: 20vw 20vw;
        margin-top:2.75vh;
        margin-bottom:2.75vh;
    }
    .key h2{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .key h3{
        color: var(--Brand-color-2, #413F3F);

/* Sub text */
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:7px;
    }
    .button{
        width:7vw;
        height:51px;
        border-radius: 8px;
        background: #25AD34;
        margin-left:7vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;

text-align: center;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
    }
}
</style>